import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";

// Import Swiper React components
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useCallback, useMemo, useRef } from "react";

const News = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const sliderRef = useRef<SwiperRef>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const posts = useMemo(() => {
    return [
      {
        image: "images/news-1.png",
        title: "This NEW Meme Coin Could 100X! 🚀 The Next MEMELAND ($MEME) ??",
        description: "17k views • 1 month ago",
        author: "Crypto Mav",
        avatar: "images/author-1.png",
      },
      {
        image: "images/news-2.png",
        title:
          "I Found The NEXT BIG Meme Coin - Get In EARLY Before It MOONS! 🌙",
        description: "23k views • 1 month ago",
        author: "100x Alts",
        avatar: "images/author-2.png",
      },
      {
        image: "images/news-3.png",
        title:
          "WARNING: This New Meme Coin Is About To EXPLODE! 🔥 Last Chance To Buy!",
        description: "15.5k views • 1 month ago",
        author: "Crypto Bull",
        avatar: "images/author-3.png",
      },
      {
        image: "images/news-4.png",
        title:
          "DO NOT Sleep On This Meme Coin! Transforming the Crypto Space 💥",
        description: "36k views • 1 month ago",
        author: "Crypto Mav",
        avatar: "images/author-4.png",
      },
    ];
  }, []);

  return (
    <Section>
      <div className="w-full flex flex-col" id="secure">
        <div className="flex flex-col w-full gap-10 items-center" ref={ref}>
          <div className="w-full flex flex-col lg:flex-row justify-between gap-4">
            <div className="flex flex-col items-center justify-between w-full lg:w-3/12 lg:px-5">
              <h2 className="mb-10 text-center lg:text-left">
                {t("news_title")}
              </h2>
            </div>

            <div className="hidden md:block v-line" />

            <div className="flex flex-col items-center w-full lg:w-9/12 px-4">
              {/* <div className="w-full relative">
                <Swiper
                  ref={sliderRef}
                  pagination={true}
                  spaceBetween={28}
                  modules={[Pagination]}
                  slidesPerView={"auto"}
                  onSlideChange={() => console.log("Slide change")}
                  breakpoints={{
                    0: {
                      centeredSlides: true,
                    },
                    1024: {
                      centeredSlides: false,
                    },
                  }}
                >
                  {posts.map((e, index) => (
                    <SwiperSlide className="w-80" key={index}>
                      <Card {...e} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="flex items-center justify-between w-full absolute bottom-0 z-20">
                  <button className="rounded-full" onClick={handlePrev}>
                    <img
                      src="images/left-arrow.png"
                      alt="Btn"
                      className="w-10 h-10"
                    />
                  </button>

                  <button className="rounded-full" onClick={handleNext}>
                    <img
                      src="images/right-arrow.png"
                      alt="Btn"
                      className="w-10 h-10"
                    />
                  </button>
                </div>
              </div> */}

              <iframe
                className="w-full h-full aspect-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/M4wZHcgBjAM?si=t2_Q_SI-UGWB2Pu_"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

// const Card: React.FC<{
//   image: string;
//   title: string;
//   description: string;
//   author: string;
//   avatar: string;
// }> = ({ image, title, description, author, avatar }) => {
//   return (
//     <div className="flex flex-col w-80 pb-16">
//       <img src={image} alt="Mask" className="w-80 h-80 object-cover mb-9" />
//       <p className="t_base font-bold mb-4">{title}</p>
//       <p className="t_sm font-medium mb-3">{description}</p>
//       <div className="flex items-center gap-2 mt-auto">
//         <img
//           src={avatar}
//           alt="Author"
//           className="w-8 h-8 object-cover rounded-full"
//         />
//         <p className="t_sm font-semibold">{author}</p>
//       </div>
//     </div>
//   );
// };

export default News;
