import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { useMemo, useRef } from "react";
import { twMerge } from "tailwind-merge";

const icons = [
  "/images/roadmap-mask-0.png",
  "/images/roadmap-mask-1.png",
  "/images/roadmap-mask-2.png",
];

const Roadmap = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const rounds = useMemo(() => {
    return [
      {
        phase: `${t("round")} 1`,
        title: t("r1_title"),
        subtitle: t("r1_sub_title"),
        descriptions: [
          t("r1_description_1"),
          t("r1_description_2"),
          t("r1_description_3"),
          t("r1_description_4"),
        ],
      },
      {
        phase: `${t("round")} 2`,
        title: t("r2_title"),
        subtitle: t("r2_sub_title"),
        descriptions: [
          t("r2_description_1"),
          t("r2_description_2"),
          t("r2_description_3"),
          t("r2_description_4"),
        ],
      },
      {
        phase: `${t("round")} 3`,
        title: t("r3_title"),
        subtitle: t("r3_sub_title"),
        descriptions: [
          t("r3_description_1"),
          t("r3_description_2"),
          t("r3_description_3"),
          t("r3_description_4"),
        ],
      },
      {
        phase: `${t("round")} 4`,
        title: t("r4_title"),
        subtitle: t("r4_sub_title"),
        descriptions: [
          t("r4_description_1"),
          t("r4_description_2"),
          t("r4_description_3"),
          t("r4_description_4"),
        ],
      },
    ];
  }, [t]);

  return (
    <Section className="relative">
      <div
        id="roadmap"
        className="w-full flex flex-col relative items-center justify-center"
      >
        <div className="flex flex-col w-full lg:w-4/5 gap-5 items-center z-20 relative py-32">
          <div className="mask_edges flex flex-col w-full relative">
            <div className="js-timeline !overflow-visible">
              <div
                className="slide"
                style={{ transform: "translateZ(0px)" }}
                ref={ref}
              >
                <div className="timeline">
                  <div className="timeline__inner-wrap">
                    <div className="timeline__list">
                      {rounds.map((e, index) => {
                        return (
                          <div
                            className="timeline__period-list js-timeline-period-list flex flex-col"
                            key={index}
                          >
                            <div
                              className={twMerge(
                                "max-w-[90vw] w-[22.5rem] min-h-[24rem] flex flex-col relative bg-dark_blue mr-9 card-rounded px-7 py-4 card-border",
                                index % 2 ? "md:mt-40" : "md:mb-40"
                              )}
                            >
                              <img
                                src={icons[index % icons.length]}
                                className="hidden md:block w-auto h-auto object-contain cursor-pointer absolute top-0 right-0 translate-x-1/3 -translate-y-1/2 z-10 drop-shadow-xl"
                                alt="Mask"
                              />
                              <h2 className="font-semibold mb-1 text-yellow">
                                {e.phase}
                              </h2>
                              <p className="t_2xl mb-5">{e.title}</p>
                              <p className="t_base mb-5">{e.subtitle}</p>

                              <ul className="marker:text-yellow flex flex-col gap-4 list-disc list-inside">
                                {e.descriptions.map((des, index) => (
                                  <li className="t_base" key={index}>
                                    {des}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="js-timeline-scrollbar timeline-scrollbar scrollbar mt-20">
            <div
              className="timeline-scrollbar__handle handle"
              style={{
                transform: "translateZ(0px) translateX(0px)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Roadmap;
