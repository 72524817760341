import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const languages = [
  { key: "sa", name: "Arabic" },
  { key: "bg", name: "Bulgarian" },
  { key: "cn", name: "Chinese" },
  { key: "cz", name: "Czech" },
  { key: "de", name: "Germany" },
  { key: "fr", name: "French" },
  { key: "gb", name: "English" },
  { key: "in", name: "Hindi" },
  { key: "hu", name: "Hungarian" },
  { key: "es", name: "Spanish" },
  { key: "id", name: "Indonesian" },
  { key: "it", name: "Italian" },
  { key: "jp", name: "Japanese" },
  { key: "kr", name: "Korean" },
  { key: "pl", name: "Polish" },
  { key: "pt", name: "Portuguese" },
  { key: "ru", name: "Russian" },
  { key: "sk", name: "Slovak" },
  { key: "th", name: "Thai" },
  { key: "tr", name: "Turkish" },
  { key: "vn", name: "Vietnam" },
];

const LANGUAGE_SELECTOR_ID = "language-selector";

export const LanguageMenu = () => {
  const [isShowingModel, setShowingModel] = useState(false);
  const { i18n } = useTranslation();

  const selectedLanguage = languages.find(
    (language) => language.key === i18n.language
  );

  const handleLanguageChange = async (language: {
    key: string;
    name: string;
  }) => {
    localStorage.setItem("language", language.key);
    await i18n.changeLanguage(language.key);
    setShowingModel(false);
  };

  useEffect(() => {
    const handleWindowClick = (event) => {
      const target = event.target.closest("button");
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setShowingModel(false);
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  return (
    <>
      <button
        onClick={() => {
          setShowingModel((prev) => !prev);
        }}
        id={LANGUAGE_SELECTOR_ID}
        className="flex items-center justify-center w-auto gap-1.5 mx-4"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.30773 6.36356C1.43191 5.25671 1.8758 4.21 2.58517 3.35134C3.29454 2.49267 4.23867 1.85923 5.30218 1.52841C4.43679 3.00094 3.93442 4.65836 3.83664 6.36356H1.30773ZM6.35855 0.0291258C2.79364 0.352402 0 3.35034 0 6.99993C0 10.6495 2.79364 13.6468 6.35855 13.9707L6.36364 13.9771L6.63409 13.9905C6.75597 13.9964 6.87797 13.9996 7 14C7.12203 13.9999 7.24404 13.9967 7.36591 13.9905L7.63636 13.9777L7.64145 13.9714C11.2064 13.6462 14 10.6495 14 6.99993C14 3.35034 11.2064 0.352402 7.64145 0.0291258L7.63636 0.0227621L7.36527 0.0093983C7.12192 -0.00313276 6.87808 -0.00313276 6.63473 0.0093983L6.36364 0.0221257L6.35855 0.0284894V0.0291258ZM7.00127 1.27259C8.11241 2.74971 8.76817 4.51907 8.88809 6.36356H5.11191C5.23202 4.519 5.888 2.74964 6.99936 1.27259H7H7.00127ZM3.836 7.6363C3.93782 9.39268 4.45964 11.0377 5.30155 12.4714C4.23803 12.1406 3.29391 11.5072 2.58453 10.6485C1.87516 9.78985 1.43127 8.74314 1.30709 7.6363H3.836ZM6.99873 12.7273C5.88737 11.2502 5.23138 9.48085 5.11127 7.6363H8.88745C8.76735 9.48085 8.11136 11.2502 7 12.7273H6.99873ZM8.69782 12.4714C9.56297 10.9988 10.0651 9.34144 10.1627 7.6363H12.6916C12.5675 8.74314 12.1236 9.78985 11.4142 10.6485C10.7048 11.5072 9.7607 12.1406 8.69718 12.4714H8.69782ZM10.1627 6.36356C10.0609 4.60717 9.53909 2.96216 8.69718 1.52841C9.76081 1.85913 10.7051 2.49252 11.4146 3.3512C12.1241 4.20987 12.568 5.25663 12.6923 6.36356H10.1634H10.1627Z"
            fill="white"
          />
        </svg>

        <p className="t_xs">{selectedLanguage?.name}</p>

        <img
          alt="Down"
          src="/images/icon_dropdown.svg"
          className="h-6 w-6 object-contain"
        />
      </button>

      {isShowingModel && (
        <div
          className="origin-top-right absolute right-10 lg:right-40 top-16 mt-2.5 p-3 rounded-2xl shadow-lg bg_primary_blur"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={LANGUAGE_SELECTOR_ID}
        >
          <div className="py-0.5 grid grid-cols-2 gap-2.5" role="none">
            {languages.map((language, index) => {
              return (
                <button
                  key={language.key}
                  onClick={() => handleLanguageChange(language)}
                  className={classNames(
                    selectedLanguage?.key === language.key && "bg-border-gray",
                    "p-1 t_xs text-start items-center inline-flex hover:bg-border-gray"
                  )}
                  role="menuitem"
                >
                  <span className={`fi fi-${language.key} mr-0.5`}></span>
                  <span className="truncate ml-2.5">{language.name}</span>
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
