import axios, { AxiosInstance } from "axios";

const ipapi: AxiosInstance = axios.create({
  baseURL: "https://ipapi.co/json",
  params: {
    key: process.env.REACT_APP_IPAPI_KEY,
  },
});

export const getUserIPData = () => ipapi.get("/");
