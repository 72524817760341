import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { twJoin, twMerge } from "tailwind-merge";
import { signup } from "../../../urls";

const GameFeatures = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  return (
    <Section>
      <div
        ref={ref}
        className="flex flex-col w-full pb-5 md:pb-10 xl:pb-20 relative items-center"
      >
        <div className="w-full flex flex-col justify-center items-center gap-5 z-0 relative">
          <h2
            className={twJoin(
              "tracking-[0.75rem] font-bold uppercase mb-16 text-yellow"
            )}
          >
            {t("game_title")}
          </h2>

          <div className="card-border h-auto bg-[#202020] p-4 md:p-8 md:pb-20 w-full grid grid-cols-1 md:grid-cols-2 relative">
            <div className="flex flex-col p-5">
              <h2 className="mb-7">{t("in_game_features")}</h2>
              <h1 className="text-yellow mb-10 md:mb-20 drop-shadow-c36px">
                {t("characters")}
              </h1>
              <div
                className="t_base w-11/12 mb-8 md:mb-16"
                dangerouslySetInnerHTML={{ __html: t("game_description") }}
              />
              <div className="z-10">
                <a
                  className="b_xs"
                  href={signup}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("buy_now")}
                </a>
              </div>
            </div>
            <div className="flex justify-end py-5">
              <div className="flex w-full md:w-10/12 h-full relative">
                <div className="flex flex-col shadow-xl card-rounded bg-dark_blue h-auto p-5 md:p-10 w-full z-10">
                  <div className="ml-auto w-full lg:w-10/12">
                    <div className="flex gap-7 items-center mb-5 md:mb-10">
                      <div className="bg-[#121212] px-3 py-1">
                        <p className="font-bold text-yellow t_lg">#11648</p>
                      </div>
                      <img
                        src="/images/bulls.png"
                        alt="Bulls"
                        className="w-auto h-auto object-contain"
                      />
                    </div>
                    <img
                      src="/images/gamer.png"
                      alt="Sticker"
                      className="block md:hidden w-4/5 h-auto object-contain z-10 drop-shadow-xl"
                    />
                    <h1 className="mb-8 drop-shadow-cxl">Warren Buffett</h1>
                    <p className="t_base mb-5">{t("character_description")}</p>
                  </div>

                  <div className="grid grid-cols-3">
                    <div className="flex flex-col items-center">
                      <img
                        src="/images/power.png"
                        alt="Power"
                        className="w-14 h-14 object-contain mb-3 drop-shadow-cxl"
                      />
                      <p className="t_sm mb-5">{t("power")}</p>
                      <Points points={5} />
                    </div>

                    <div className="flex flex-col items-center">
                      <img
                        src="/images/skill.png"
                        alt="Skill"
                        className="w-14 h-14 object-contain mb-3 drop-shadow-cxl"
                      />
                      <p className="t_sm mb-5">{t("skill")}</p>
                      <Points points={5} />
                    </div>

                    <div className="flex flex-col items-center">
                      <img
                        src="/images/luck.png"
                        alt="Knowledge"
                        className="w-14 h-14 object-contain mb-3 drop-shadow-cxl"
                      />
                      <p className="t_sm mb-5">{t("luck")}</p>
                      <Points points={5} />
                    </div>
                  </div>
                </div>

                <img
                  src="/images/game-card-mask.png"
                  alt="Sticker"
                  className="max-w-[30%] w-auto h-auto object-contain z-0 absolute bottom-0 left-0 -translate-x-3/4"
                />
                <img
                  src="/images/gamer.png"
                  alt="Sticker"
                  className="hidden md:block max-w-[50%] w-auto h-auto object-contain z-10 absolute top-0 left-0 -translate-y-[15%] -translate-x-3/4 drop-shadow-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const Points: React.FC<{ points: number }> = ({ points }) => {
  return (
    <div className="flex items-center gap-1">
      {Array(5)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className={twMerge(
              "bg-yellow/20 w-3 h-3 rounded-full",
              index + 1 <= points && "bg-yellow"
            )}
          />
        ))}
    </div>
  );
};

export default GameFeatures;
