import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data } = error.response;
      return Promise.reject(
        new Error(data?.message || "Something went wrong.")
      );
    } else {
      return Promise.reject(new Error("Something went wrong."));
    }
  }
);
export default instance;
