import { Footer } from "../components/Footer";
import Layout from "../components/Layouts/Layout";
import Mask from "../components/Mask";
import Section from "../components/Section";
import { TradeAlert } from "../components/TradeAlert";
import { internal } from "../urls";

const CookiePolicy = () => {
  return (
    <Layout>
      <Section>
        <div className="flex flex-col pt-[5rem] z-20 mx-auto w-full md:w-10/12 gap-4">
          <h2>Cookies Policy - Meme Moguls</h2>
          <p className="t_base">
            Welcome to Meme Moguls! This Cookies Policy explains how we use
            cookies and similar technologies on our website to enhance your
            browsing experience. By continuing to use our website, you consent
            to the use of cookies as described in this policy.
          </p>
          <p className="t_xl font-semibold">What are Cookies?</p>

          <p className="t_base">
            Cookies are small text files that are stored on your device
            (computer, tablet, or mobile) when you visit a website. They help us
            improve our website and provide a personalized experience by
            remembering your preferences and actions.
          </p>

          <p className="t_xl font-semibold">Types of Cookies We Use</p>

          <ul className="list-decimal list-inside flex flex-col gap-2">
            <li className="t_base">
              Essential Cookies: These cookies are necessary for the functioning
              of our website and enable basic features, such as page navigation
              and access to secure areas. Without these cookies, our website may
              not work correctly.
            </li>

            <li className="t_base">
              Analytics Cookies: We use analytics cookies to gather information
              about how visitors interact with our website. This helps us
              improve the website's performance, navigation, and user
              experience. The data collected is aggregated and anonymous.
            </li>

            <li className="t_base">
              Functional Cookies: These cookies allow our website to remember
              choices you make and provide enhanced features and personalized
              content. They may also be used to provide services you have
              requested, such as language preferences or user-generated content.
            </li>

            <li className="t_base">
              Advertising Cookies: We may use advertising cookies to deliver
              relevant advertisements to you based on your interests. These
              cookies track your browsing habits and enable us to show you
              targeted ads on our website or third-party platforms. We do not
              share your personal information with advertisers.
            </li>
          </ul>

          <p className="t_xl font-semibold">Managing Cookies</p>

          <p className="t_base">
            You can control and manage cookies preferences through your browser
            settings. Most browsers allow you to refuse or accept cookies,
            delete cookies already stored on your device, or set preferences for
            specific websites. Please note that disabling or deleting certain
            cookies may affect the functionality and user experience of our
            website.
          </p>

          <p className="t_xl font-semibold">Third-Party Cookies</p>

          <p className="t_base">
            We may use third-party cookies on our website, such as those from
            social media platforms or advertising networks. These cookies are
            subject to the respective third parties' privacy policies, and we
            recommend reviewing their policies for more information.
          </p>

          <p className="t_xl font-semibold">Updates to the Cookies Policy</p>

          <p className="t_base">
            We may update this Cookies Policy from time to time to reflect
            changes in technology, legal requirements, or our practices. We
            encourage you to review this policy periodically for any updates.
          </p>
          <p className="t_xl font-semibold">Managing Cookies</p>
          <p className="t_base">
            You have the ability to manage or delete cookies by adjusting your
            browser settings. However, please note that disabling essential
            cookies may impact the functionality of certain crypto-related
            features on our website.
          </p>
          <p className="t_base">
            If you have any queries or concerns regarding our use of cookies
            within the crypto context or this policy, please feel free to
            contact us at{" "}
            <a className="text-link" href="mailto:Contact@mememoguls.com">
              Contact@mememoguls.com
            </a>
            .
          </p>
          <p className="t_base">
            By interacting with{" "}
            <a className="text-link" href={internal.home}>
              mememoguls.com
            </a>
            , you agree to the use of cookies as outlined in this Cookies
            Policy.
          </p>
        </div>
      </Section>

      <Mask bottomSeparator topSeparator>
        <Footer />
      </Mask>

      <TradeAlert />
    </Layout>
  );
};

export default CookiePolicy;
