import { Footer } from "../components/Footer";
import Layout from "../components/Layouts/Layout";
import Mask from "../components/Mask";
import Section from "../components/Section";
import { TradeAlert } from "../components/TradeAlert";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Section>
        <div className="flex flex-col pt-[5rem] z-20 mx-auto w-full md:w-10/12 gap-4">
          <h2>Privacy Policy - Meme Moguls</h2>
          <p className="t_base">
            At Meme Moguls, we value your privacy. This privacy policy explains
            what information we collect from you and how we use it. We will
            never release your private information to third parties.
          </p>
          <p className="t_xl font-semibold">What Data We Collect</p>

          <p className="t_base">
            We might collect some information to help us improve our services:
          </p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              Your Name and Email: if you choose to provide them.
            </li>
            <li className="t_base">
              Technical Information: like your IP address and browser type.
            </li>
            <li className="t_base">
              Crypto Information: such as your wallet addresses.
            </li>
          </ul>

          <p className="t_xl font-semibold">How We Use Your Data</p>

          <p className="t_base">We use this information to:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">Make our website work for you.</li>
            <li className="t_base">Keep everything safe and secure.</li>
            <li className="t_base">
              Help us understand what you need so we can make our website
              better.
            </li>
            <li className="t_base">Keeping Your Data Safe</li>
            <li className="t_base">
              We take special care to keep your information safe:
            </li>
          </ul>

          <p className="t_xl font-semibold">Holding onto Your Data</p>

          <p className="t_base">
            We only keep your information for as long as we need it. After that,
            we delete it. No Third Parties
          </p>
          <p className="t_base">
            We promise not to give your information to any third-party
            companies. Your privacy is important to us.
          </p>

          <p className="t_base">Talk to Us</p>

          <p className="t_base">
            If you have any questions or need help with anything, you can email
            us at <br />
            <a className="text-link" href="mailto:Contact@mememoguls.com">
              Contact@mememoguls.com
            </a>
            .
          </p>
        </div>
      </Section>

      <Mask bottomSeparator topSeparator>
        <Footer />
      </Mask>

      <TradeAlert />
    </Layout>
  );
};

export default PrivacyPolicy;
