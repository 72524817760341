import { useMemo, useState } from "react";
import Section from "../../../components/Section";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { twJoin, twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const { ref: title, inView: ivTitle } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  const questions = useMemo(
    () => [
      {
        title: t("question_1"),
        description: t("answer_1"),
      },
      {
        title: t("question_2"),
        description: t("answer_2"),
      },
      {
        title: t("question_3"),
        description: t("answer_3"),
      },
      {
        title: t("question_4"),
        description: t("answer_4"),
      },
      {
        title: t("question_5"),
        description: t("answer_5"),
      },
      {
        title: t("question_6"),
        description: t("answer_6"),
      },
      {
        title: t("question_7"),
        description: t("answer_7"),
      },
    ],
    [t]
  );

  return (
    <Section>
      <div
        ref={title}
        className="mx-auto w-full md:w-9/12 flex flex-col items-center justify-center gap-2 relative"
      >
        <div className="flex items-center justify-center w-full mb-12">
          <h1
            className={twJoin(
              "transition duration-200 text-center",
              ivTitle && "animate-fade-down"
            )}
          >
            {t("faq_title")}
          </h1>
        </div>

        <div className="flex flex-col gap-5 w-full items-center">
          {questions.map((e, index) => {
            return <FAQItem key={index} {...e} />;
          })}
        </div>
      </div>
    </Section>
  );
};

export default FAQs;

// extension
const FAQItem = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const [isShowingAnswer, setShowingAnswer] = useState(false);
  return (
    <div
      onClick={() => setShowingAnswer((prev) => !prev)}
      className={classNames(
        "w-full bg-[#424242] p-5 cursor-pointer duration-200 ease-in-out backdrop-blur-md z-20 rounded-3xl border-[3px] border-yellow"
      )}
    >
      <div className="grid grid-cols-[1fr_auto] items-center gap-2">
        <p className="t_2xl uppercase">{title}</p>

        <div className="flex flex-col items-center">
          <svg
            className={twMerge(
              "w-4 h-4 md:w-6 md:h-6",
              isShowingAnswer ? "rotate-90" : "rotate-0"
            )}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.26276 25.137C8.93468 24.8089 8.75038 24.3638 8.75038 23.8998C8.75038 23.4358 8.93468 22.9907 9.26276 22.6625L17.9253 14L9.26276 5.33754C8.94398 5.00749 8.76759 4.56543 8.77158 4.10659C8.77556 3.64774 8.95961 3.20882 9.28407 2.88435C9.60854 2.55989 10.0475 2.37585 10.5063 2.37186C10.9651 2.36787 11.4072 2.54426 11.7373 2.86304L21.637 12.7628C21.9651 13.091 22.1494 13.536 22.1494 14C22.1494 14.4641 21.9651 14.9091 21.637 15.2373L11.7373 25.137C11.4091 25.4651 10.964 25.6494 10.5 25.6494C10.036 25.6494 9.59093 25.4651 9.26276 25.137Z"
              fill="#FFF622"
            />
          </svg>
        </div>
      </div>

      {isShowingAnswer && (
        <p
          className="text-white t_lg pr-20 mt-4"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      )}
    </div>
  );
};
