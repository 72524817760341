import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const Mask: React.FC<{
  image?: string;
  children: ReactNode;
  topSeparator?: boolean;
  bottomSeparator?: boolean;

  maskClassNames?: string;
}> = ({ image, children, topSeparator, bottomSeparator, maskClassNames }) => {
  return (
    <>
      {topSeparator && (
        <div className="h-line-wrapper">
          <div className="h-line" />
        </div>
      )}
      <div className="limited-content">
        <div
          className={twMerge(
            "absolute top-0 bottom-0 w-full h-auto max-h-full pointer-events-none z-0",
            maskClassNames
          )}
        >
          {image && <img src={image} alt="Mask" className="mask-image" />}
          <div className="absolute h-1/6 inset-x-0 top-0 bg-gradient-to-b from-dark_blue from-10% to-transparent"></div>
          <div className="hidden edge:block absolute h-full w-1/12 inset-y-0 left-0 bg-gradient-to-r from-dark_blue to-transparent"></div>
          <div className="hidden edge:block absolute h-full w-1/12 inset-y-0 right-0 bg-gradient-to-l from-dark_blue to-transparent"></div>
          <div className="absolute h-1/6 inset-x-0 bottom-0 bg-gradient-to-b from-transparent to-90% to-dark_blue"></div>
        </div>
        <div className="z-10 relative">{children}</div>
      </div>

      {bottomSeparator && (
        <div className="h-line-wrapper">
          <div className="h-line" />
        </div>
      )}
    </>
  );
};

export default Mask;
