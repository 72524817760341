import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { twJoin, twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { Fragment, useMemo } from "react";

const Guide = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { t } = useTranslation();

  const guideSteps = useMemo(
    () => [
      {
        image: "images/why_us_0.png",
        title: t("guide_title_1"),
        description: t("guide_description_1"),
      },
      {
        image: "images/why_us_1.png",
        title: t("guide_title_2"),
        description: t("guide_description_2"),
      },
      {
        image: "images/why_us_2.png",
        title: t("guide_title_3"),
        description: t("guide_description_3"),
      },
    ],
    [t]
  );

  return (
    <Section>
      <div className="w-full flex flex-col pb-20" id="eco-system" ref={ref}>
        <div className="flex flex-col">
          <p
            className={twJoin(
              "t_section uppercase duration-200 text-center mb-16 md:mb-32 leading-none drop-shadow-c36px",
              inView ? "animate-fade-down" : "opacity-0"
            )}
          >
            {t("why_us_title")}
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-5 md:gap-0">
          {guideSteps.map((e, index) => (
            <Fragment key={index}>
              <div
                key={index}
                className={twMerge(
                  "flex flex-col duration-200 relative flex-1 items-center",
                  inView && "animate-fade-right",

                  index === 0 && "animate-delay-0",
                  index === 1 && "animate-delay-100",
                  index === 2 && "animate-delay-200"
                )}
              >
                <div className="flex w-full md:w-8/12 mx-auto flex-row md:flex-col gap-5 items-center">
                  <img
                    src={e.image}
                    className="w-1/3 h-auto md:w-auto md:h-[12.5rem] object-contain mb-6 drop-shadow-xl"
                    alt="Feature"
                  />

                  <div className="w-2/3 md:w-auto">
                    <h2 className="text-left md:text-center text-yellow mb-3.5 md:mb-7 lg:min-h-[96px]">
                      {e.title}
                    </h2>
                    <div className="h-line mb-5"></div>
                    <p
                      className="text-left t_base md:leading-8"
                      dangerouslySetInnerHTML={{ __html: e.description }}
                    ></p>
                  </div>
                </div>
              </div>

              {index < guideSteps.length - 1 && <div className="v-line" />}
            </Fragment>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Guide;
