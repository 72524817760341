import React from "react";
import Section from "../../components/Section";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const Format = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  return (
    <Section>
      <div className="grid grid-cols-1 gap-24 md:grid-cols-2" ref={ref}>
        <div
          className={twMerge(
            "duration-200 animate-delay-0",
            inView && "animate-fade-right"
          )}
        >
          <div className="card-border bg-[#202020] t_36px font-bold flex items-center relative ">
            <img
              src="images/give_away_trophy_icon.png"
              alt="Mask"
              className="w-[80px] md:w-[120px] h-auto absolute -left-[20px]"
            />
            <span className="block p-4 md:p-5 pl-14 md:pl-28">
              {t("give_away_competition_format")}
            </span>
          </div>

          <div className="mt-8 font-normal md:mt-16 t_2xl">
            <span>{t("give_away_competition_description_1")}</span>
          </div>

          <div className="mt-8 md:mt-16">
            <span className="font-bold text-dark_yellow t_2xl">
              {t("give_away_competition_description_2")}
            </span>
          </div>
        </div>
        <div
          className={twMerge(
            "duration-200 animate-delay-0",
            inView && "animate-fade-left"
          )}
        >
          <div className="card-border bg-[#202020] t_36px font-bold flex items-center relative ">
            <img
              src="images/give_away/give_away_box_icon.png"
              alt="Mask"
              className="w-[80px] md:w-[120px] h-auto absolute -left-[20px]"
            />
            <span className="block p-4 pl-16 md:p-5 md:pl-28">
              {t("give_away_prize_distribution")}
            </span>
          </div>

          <div className="mt-8 font-normal md:mt-16 t_2xl">
            <span
              dangerouslySetInnerHTML={{
                __html: t("give_away_prize_distribution_description_1"),
              }}
            />
          </div>

          <div className="mt-8 md:mt-16">
            <span className="font-bold text-dark_yellow t_2xl">
              {t("give_away_prize_distribution_description_2")}
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};
