import React from "react";
import Mask from "../../components/Mask";
import Section from "../../components/Section";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const Banner = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  return (
    <div className="mt-[104px] md:mt-[8.25rem]" ref={ref}>
      <Mask image="images/give_away_banner.png">
        <Section>
          <div className="relative flex w-full h-full md:min-h-[50rem] ">
            <div
              className={twMerge(
                "flex flex-col justify-center flex-1 duration-200 animate-delay-0",
                inView && "animate-fade-right"
              )}
            >
              <div className="flex items-center">
                <h1 className="font-bold text-yellow font-inter text-2xl md:text-4xl xl:text-[115px] ">
                  #1
                </h1>
                <h3 className="font-bold t_48px font-inter">
                  &nbsp; {t("give_away_banner_community")}
                </h3>
              </div>

              <h1
                className="font-bold text-yellow font-inter text-2xl md:text-3xl xl:text-[84px] xl:leading-[100px] mt-6 md:mt-12"
                dangerouslySetInnerHTML={{
                  __html: t("give_away_banner_title"),
                }}
              />
              <h3 className="block mt-6 md:mt-8 t_36px"
              dangerouslySetInnerHTML={{
                __html: t("give_away_banner_price"),
              }} />
            </div>

            <img
              src="images/christmas_special.png"
              alt="Mask"
              className={twMerge(
                "w-auto h-[120px]  md:h-[231px]  right-0 absolute -top-10 md:-top-20 duration-200 animate-delay-0",
                inView && "animate-fade-left"
              )}
            />
            <img
              src="images/give_away_banner_image.png"
              alt="Mask"
              className={twMerge(
                "h-[calc(100%-120px)] md:h-[calc(100%-331px)] mt-auto hidden md:block absolute bottom-0 -right-40 duration-200 animate-delay-0",
                inView && "animate-fade-left"
              )}
            />
          </div>
        </Section>
      </Mask>
    </div>
  );
};
