import Section from "../../../components/Section";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { signup, solidProofAudit } from "../../../urls";

const Challenges = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section className="!pt-0">
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10" ref={ref}>
        <img
          className={twMerge(
            "w-full object-contain transition duration-200 md:p-5",
            inView ? "animate-fade-right" : "opacity-0"
          )}
          src="/images/challenges.png"
          alt="Challenges"
        />
        <div className="flex flex-col w-full gap-6 justify-center md:p-5 z-0">
          <h2 className="text-center md:text-left">{t("challenges_title")}</h2>
          <p
            className="t_base text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: t("challenges_description") }}
          ></p>
          <div className="flex gap-4 items-center justify-center md:justify-start">
            <a className="b_xs" href={signup} target="_blank" rel="noreferrer">
              {t("buy_now")}
            </a>
            <a
              href={solidProofAudit}
              target="_blank"
              className="b_xs_outline"
              rel="noreferrer"
            >
              {t("audit")}
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Challenges;
