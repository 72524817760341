import React, { ReactNode } from "react";

type RootProps = {
  children: ReactNode;
};

const RootLayout: React.FC<RootProps> = ({ children }) => {
  return <>{children}</>;
};

export default RootLayout;
