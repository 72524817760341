import { Footer } from "../components/Footer";
import Layout from "../components/Layouts/Layout";
import Mask from "../components/Mask";
import Section from "../components/Section";
import { TradeAlert } from "../components/TradeAlert";

const TermsConditions = () => {
  return (
    <Layout>
      <Section>
        <div className="flex flex-col pt-[5rem] z-20 mx-auto w-full md:w-10/12 gap-4">
          <h2>Terms and Conditions - Meme Moguls</h2>

          <p className="t_base">
            Welcome to Meme Moguls! These Terms of Use govern your access and
            use of our Platforms, including our website (www.mememoguls.com),
            mobile applications, and other digital platforms. Before you
            proceed, please carefully read and understand these Terms of Use. By
            accessing or using our Platforms, you indicate your acceptance and
            agreement to abide by these Terms of Use. If you do not agree to
            these terms, please refrain from using our Platforms.
          </p>

          <p className="t_base">About Us:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              We, Meme Moguls (referred to as "we," "our," or "us"), operate a
              play-to-earn memecoin on the Ethereum network. Our team has
              developed smart contracts to facilitate this.
            </li>
            <li className="t_base">
              To enhance your experience, we are actively working on introducing
              mobile applications and additional digital platforms in the
              future. Our Platforms, which include our Website, mobile
              applications, other digital platforms, and smart contracts, are
              owned and managed by us.
            </li>
            <li className="t_base">
              The information and content provided on our Platforms are not
              intended for distribution or use in any jurisdiction or country
              where such distribution or use is prohibited by law or regulation,
              or would require us to comply with registration requirements. If
              you access our Platforms from such locations, you do so at your
              own discretion and are solely responsible for complying with
              applicable local laws.
            </li>
          </ul>

          <p className="t_base">Our Privacy Policy:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              Alongside these Terms of Use, please note that our Privacy Policy
              regulates the processing of personal data collected from you or
              provided by you when using our Platforms. We encourage you to
              review our Privacy Policy to familiarize yourself with how we
              handle your personal information.
            </li>
          </ul>

          <p className="t_base">Changes to these Terms of Use:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              We retain the right to modify these Terms of Use from time to time
              by updating this page. We advise you to regularly check this page
              for any changes, as they are applicable to you.
            </li>

            <li className="t_base">
              The latest edition of these Terms of Use will always be available
              on our Website. Any new version will take effect immediately upon
              posting and will govern the use of our Platforms and your
              association with us. By continuing to use our Platforms, you
              acknowledge, accept, and consent to be bound by the revised terms
              and amendments.
            </li>
          </ul>

          <p className="t_base">Changes to our Platforms:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              We may occasionally update and modify our Platforms, including its
              content. However, please be aware that certain content on our
              Platforms may become outdated, and we are not obliged to update
              it.
            </li>

            <li className="t_base">
              We do not provide a guarantee that our Platforms or any of its
              content will be free from errors or omissions.
            </li>
          </ul>

          <p className="t_base">Accessing our Platforms:</p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              Our Platforms are provided free of charge.
            </li>

            <li className="t_base">
              We do not guarantee uninterrupted availability of our Platforms or
              its content. Access to our Platforms may be temporary, and we
              reserve the right to suspend, withdraw, discontinue, or modify all
              or part of our Platforms without prior notice. We will not be
              liable if our Platforms become unavailable at any time or for any
              duration.
            </li>

            <li className="t_base">
              You are responsible for making the necessary arrangements to
              access our Platforms.
            </li>
            <li className="t_base">
              It is your responsibility to ensure that anyone accessing our
              Platforms through your internet connection or device is aware of
              these Terms of Use and other relevant terms and conditions, and
              complies with them.
            </li>
            <li className="t_base">
              You must not use our Platforms for any illegal or unauthorized
              purposes.
            </li>
            <li className="t_base">
              Automated or non-human access to our Platforms, including through
              bots, scripts, or similar means, is strictly prohibited
            </li>
            <li className="t_base">
              We do not guarantee that the content available on our Platforms is
              appropriate or accessible in your location. We may restrict access
              to our Platforms, services, or products to specific individuals or
              geographic areas at any time. If you choose to access our
              Platforms from your location, you do so at your own risk.
            </li>
          </ul>

          <p className="t_base">Prohibited Use:</p>
          <p className="t_base">
            To ensure a positive and secure environment, certain activities are
            strictly prohibited on our Platforms. By accessing or using our
            Platforms, you agree not to engage in the following activities
            without our written permission:
          </p>
          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              Unauthorized use: Making any unauthorized use of our Platforms,
              including collecting usernames or email addresses of users for
              unsolicited emails, or creating user accounts through automated
              means or false pretenses.
            </li>

            <li className="t_base">
              Security interference: Circumventing, disabling, or interfering
              with security-related features of our Platforms that protect
              content and enforce usage limitations.
            </li>

            <li className="t_base">
              Disruption: Interfering with, disrupting, or causing an undue
              burden on our Platforms, networks, or services connected to our
              Platforms.
            </li>

            <li className="t_base">
              Bypassing measures: Attempting to bypass any measures designed to
              restrict access to our Platforms or any part thereof.
            </li>

            <li className="t_base">
              Data retrieval: Systematically retrieving data or content from our
              Platforms to create collections, compilations, databases, or
              directories.
            </li>

            <li className="t_base">
              Deceptive practices: Attempting to deceive, defraud, or mislead us
              or other users, particularly in acquiring sensitive account
              information or impersonating another user.
            </li>

            <li className="t_base">
              Harassment or harm: Using information obtained from our Platforms
              to harass, abuse, or harm others.
            </li>

            <li className="t_base">
              Use of agents: Employing buying or purchasing agents to make
              transactions on our Platforms.
            </li>

            <li className="t_base">
              Automated use: Engaging in automated use of our system, such as
              employing scripts, data mining, robots, or similar tools for
              commenting, messaging, or data extraction.
            </li>
            <li className="t_base">
              Malicious content: Uploading or transmitting viruses, Trojans, or
              other material that disrupts or modifies the operation, features,
              or enjoyment of our Platforms.
            </li>
            <li className="t_base">
              Information collection mechanisms: Uploading or transmitting
              passive or active information collection devices, such as gifs,
              pixels, web bugs, cookies, or similar mechanisms.
            </li>
            <li className="t_base">
              Unauthorized scripts or software: Using, launching, developing, or
              distributing any unauthorized script, software, spider, robot,
              cheat utility, scraper, or offline reader to access our Platforms.
            </li>
            <li className="t_base">
              Reverse engineering: Deciphering, decompiling, disassembling, or
              reverse engineering any software comprising our Platforms.
            </li>
            <li className="t_base">
              Removal of notices: Deleting copyright or proprietary rights
              notices from any content on our Platforms.
            </li>
            <li className="t_base">
              Software copying: Copying or adapting the software underlying our
              Platforms, including Flash, PHP, HTML, JavaScript, or other code.
            </li>
            <li className="t_base">
              Competitive use: Using our Platforms to compete with us or for any
              revenue-generating or commercial purposes.
            </li>
            <li className="t_base">
              Misuse of support services: Making improper use of our support
              services or submitting false reports of abuse or misconduct.
            </li>
            <li className="t_base">
              Harassment of staff: Harassing, intimidating, or threatening our
              employees or agents involved in providing our Platforms.
            </li>
            <li className="t_base">
              Unauthorized advertising: Using our Platforms to advertise or
              offer unauthorized goods or services.
            </li>
            <li className="t_base">
              Unauthorized framing or linking: Engaging in unauthorized framing
              or linking to our Platforms.
            </li>
            <li className="t_base">
              Disparagement: Disparaging, tarnishing, or otherwise harming us or
              our Platforms, as determined in our opinion.
            </li>
            <li className="t_base">
              Non-compliance with laws: Using our Platforms in a manner
              inconsistent with applicable laws or regulations.
            </li>
          </ul>
          <p className="t_base">
            We reserve the right to take appropriate action, including legal
            recourse, if any user violates these prohibited activities.
          </p>

          <p className="t_base">Intellectual Property:</p>

          <p className="t_base">
            We are the owner or licensee of all intellectual property rights in
            our Platforms, including the material published on it. This includes
            source codes, databases, functionalities, software, website designs,
            audio, video, texts, photographs, and graphics. These works are
            protected by copyright, trademark, and other intellectual property
            laws.
          </p>
          <p className="t_base">
            Trademarks displayed on our Platforms are either owned by us or
            licensed to us by third parties. Other trademarks are the property
            of their respective owners. You are not permitted to use any
            trademark displayed on our Platforms without our prior written
            permission or the permission of the trademark owner.
          </p>
          <p className="t_base">
            All content on our Platforms is either protected by copyright or
            licensed to us. You are not authorized to sell, reproduce,
            distribute, modify, display, perform, report, or create derivative
            works based on any part of the content on our Platforms for public
            or commercial purposes without obtaining a license from us or our
            licensors. You are also not authorized to display or communicate the
            content on our Platforms on any other platform without a license
            from us or our licensors.
          </p>
          <p className="t_base">
            You are not authorized to systematically retrieve data or content
            from our Platforms without our written permission.
          </p>
          <p className="t_base">
            If you breach these Terms of Use, your permission to use our
            Platforms will automatically terminate, and any copies of the
            content must be immediately destroyed. Unauthorized use of our
            content may infringe copyright, trademark, privacy, publicity, and
            communication laws and regulations.
          </p>

          <p className="t_base">No Reliance on Information:</p>
          <p className="t_base">
            The content on our Platforms is provided for general information
            purposes only and should not be relied upon as advice. You should
            seek professional or specialist advice before taking any action
            based on the content on our Platforms. While we make efforts to
            ensure the information on our Platforms is accurate and up-to-date,
            we make no guarantees or warranties regarding its accuracy or
            completeness.
          </p>

          <p className="t_base">Limitation of Liability:</p>
          <p className="t_base">
            To the extent permitted by law, we exclude all conditions,
            warranties, representations, or other terms that may apply to our
            Platforms or its content, whether express or implied. We do not
            warrant or represent that the content on our Platforms is accurate,
            error-free, or reliable, or that the use of our Platforms will not
            infringe on the rights of third parties.
          </p>
          <p className="t_base">
            We will not be liable to any user for any loss or damage, whether in
            contract, tort (including negligence), breach of statutory duty, or
            otherwise, arising from the use of our Platforms or reliance on its
            content. This includes loss of profits, sales, business, revenue,
            business interruption, loss of anticipated savings, loss of business
            opportunity, goodwill, reputation, or any indirect or consequential
            loss or damage.
          </p>
          <p className="t_base">
            If you are a consumer user, we have no liability to you for any loss
            of profit, loss of business, business interruption, or loss of
            business opportunity arising from the use of our Platforms for
            commercial or business purposes.
          </p>
          <p className="t_base">
            You are responsible for any taxes associated with your use of our
            Platforms, except for taxes on our net income. You agree to pay or
            reimburse us for any national, federal, state, local, or other taxes
            and assessments imposed on us related to your use of our Platforms.
          </p>
          <p className="t_base">
            We are not liable for any loss or damage caused by viruses,
            distributed denial-of-service attacks, or other technologically
            harmful material that may infect your computer equipment, programs,
            data, or other proprietary material due to your use of our Platforms
            or downloading of content.
          </p>
          <p className="t_base">
            We assume no responsibility for the content of websites linked on
            our Platforms and will not be liable for any loss or damage arising
            from your use of those websites.
          </p>
          <p className="t_base">
            You agree to defend, indemnify, and hold us harmless from any loss,
            damage, liability, claim, or demand arising from your use of our
            Platforms, breach of these Terms of Use, breach of your
            representations and warranties, violation of third-party rights, or
            any harmful act by another person using our Platforms with whom you
            are connected.
          </p>

          <p className="t_base">
            These limitations of liability form an essential basis of our
            agreement and are a reasonable and fair allocation of risk between
            us.
          </p>
          <p className="t_base">
            We will use reasonable efforts to notify you of any claims, actions,
            or proceedings subject to indemnification and will have the right to
            assume exclusive defense and control of such matters at your
            expense. You agree to cooperate with our defense in such cases.
          </p>
          <p className="t_base">Assumption of Risks:</p>
          <p className="t_base">
            By using our Platforms, you accept and acknowledge the following
            risks:
          </p>

          <ul className="list-disc list-inside flex flex-col gap-2">
            <li className="t_base">
              The prices of blockchain assets, including $MGLS tokens, are
              highly volatile and can fluctuate significantly. There is a risk
              of losing money.
            </li>
            <li className="t_base">
              You are responsible for determining and complying with any
              applicable taxes related to your transactions.
            </li>
            <li className="t_base">
              There are risks associated with using internet-based and digital
              assets, including hardware and software issues, internet
              connectivity problems, viruses, unauthorized access, and
              communication failures. We are not responsible for any such
              issues.
            </li>
            <li className="t_base">
              The development of the Meme Moguls ecosystem and the value of NFTs
              may be negatively affected by a lack of use or public interest.
            </li>
            <li className="t_base">
              The regulatory landscape for blockchain technologies,
              cryptocurrencies, and tokens is uncertain, and new regulations may
              impact the Meme Moguls ecosystem and the value of $MGLS tokens.
            </li>
            <li className="t_base">
              Updates and patches to the Ethereum network may have unintended
              adverse effects on $MGLS tokens.
            </li>
          </ul>
          <p className="t_base">Uploading Content to our Platforms:</p>
          <p className="t_base">
            When you upload any content or make submissions to our Platforms,
            you must comply with the content standards set out in these Terms of
            Use. You warrant that your submissions comply with these standards
            and will indemnify us for any breach of this warranty.
          </p>
          <p className="t_base">
            Any content you upload to our Platforms is considered
            non-confidential and non-proprietary. While you retain ownership
            rights, you grant us and other users a limited license to use,
            store, copy, distribute, and make your content available to third
            parties. We may also disclose your identity to third parties if your
            content violates their intellectual property rights or privacy.
          </p>
          <p className="t_base">
            We are not responsible for the content or accuracy of any
            user-generated content on our Platforms. We have the right to remove
            any postings that do not comply with our content standards.
          </p>
          <p className="t_base">
            The views expressed by other users on our Platforms do not represent
            our views or values.
          </p>
          <p className="t_base">
            You are solely responsible for securing and backing up your content.
          </p>
          <p className="t_base">Viruses:</p>
          <p className="t_base">
            We do not guarantee that our Platforms will be secure or free from
            bugs or viruses. It is your responsibility to configure your device,
            information technology, computer programs, and platform to access
            our Platforms. You should use your own virus protection software.
          </p>
          <p className="t_base">
            You must not misuse our Platforms by introducing viruses, trojans,
            worms, logic bombs, or any other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorized
            access to our Platforms or any servers, computers, or databases
            connected to our Platforms. You must not attack our Platforms
            through a denial-of-service attack or a distributed
            denial-of-service attack. Any breach of this provision may be
            considered a criminal offense. We will report such breaches to the
            relevant law enforcement authorities and cooperate with them by
            disclosing your identity. In the event of a breach, your right to
            use our Platforms will cease immediately.
          </p>
          <p className="t_base">Linking to our Platforms:</p>
          <p className="t_base">
            You may link to our Platforms as long as it is fair, legal, and does
            not damage our reputation or take advantage of it. The link must not
            suggest any form of association, approval, or endorsement on our
            part where none exists. Our site must not be framed on any other
            site. We reserve the right to withdraw linking permission without
            notice. The website you are linking from must comply with the
            content standards set out in these Terms of Use.
          </p>
          <p className="t_base">
            If you wish to make any use of content on our Platforms beyond what
            is allowed above, please contact us at{" "}
            <a className="text-link" href="mailto:Contact@mememoguls.com">
              Contact@mememoguls.com
            </a>
            .
          </p>
          <p className="t_base">Third-Party Links and Resources:</p>
          <p className="t_base">
            Our Platforms may contain links to third-party sites and resources.
            These links are provided for informational purposes only, and we
            have no control over the content of those sites or resources. The
            inclusion of these links does not imply endorsement by us. We are
            not responsible for the content or accuracy of any off-site pages or
            third-party sites linked to our Platforms. Clicking on these links
            is done at your own risk.
          </p>
          <p className="t_base">
            We may also allow third parties to display advertisements and other
            information on our Platforms. We provide the space for these
            advertisements but have no other relationship with these third-party
            advertisers. The inclusion of advertisements does not imply
            endorsement by us. We are not responsible for the content or
            accuracy of any such advertisements. Clicking on advertisement links
            to off-site pages or third-party sites is done at your own risk.
          </p>
          <p className="t_base">Termination and Suspension:</p>
          <p className="t_base">
            We reserve the right to terminate, block, or suspend your access to
            our Platforms if we believe you have violated these Terms of Use or
            our rights, or if we have reasonable grounds to do so. We may also
            modify or discontinue providing access to our Platforms without
            notice. We will not be liable to you or any third party as a result
            of such actions. The provisions titled "Limitation of our Liability"
            and "General Provisions" will survive termination of these Terms of
            Use.
          </p>
          <p className="t_base">General Provisions:</p>
          <p className="t_base">
            We do not guarantee that the content on our Platforms is appropriate
            or available for use in every country. It is your responsibility to
            comply with applicable local laws regarding access to our Platforms.
          </p>
          <p className="t_base">
            If any provision of these Terms of Use is found to be illegal,
            invalid, or unenforceable, it will not affect the legality,
            validity, or enforceability of the remaining provisions unless
            otherwise required by law.
          </p>
          <p className="t_base">
            These Terms of Use constitute the entire agreement between you and
            us regarding the use of our Platforms and supersede any prior
            agreements or arrangements.
          </p>
          <p className="t_base">
            Any waiver by us of a breach of these Terms of Use will not be
            interpreted as a waiver of any other or subsequent breach.
          </p>
          <p className="t_base">Partnerships:</p>
          <p className="t_base">
            Official partnerships between our Platforms and third parties will
            be announced on our Platforms and the third party's platform. Any
            announcement of a partnership solely by a third party may be false.
          </p>
          <p className="t_base">Contact Us:</p>

          <p className="t_base">
            If you have any questions or comments about these Terms of Use or
            any other matters, please contact us at{" "}
            <a className="text-link" href="mailto:Contact@mememoguls.com">
              Contact@mememoguls.com
            </a>
            . You can also use this email address to request a copy of the
            personal data we hold about you. If you have any questions or need
            help with anything, you can email us at <br />
          </p>

          <p className="t_base">Thank you for using our Platforms.</p>
        </div>
      </Section>

      <Mask bottomSeparator topSeparator>
        <Footer />
      </Mask>

      <TradeAlert />
    </Layout>
  );
};

export default TermsConditions;
