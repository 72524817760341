import Section from "../../../components/Section";

export const Banner = () => {
  return (
    <Section
      className="pt-[8rem] md:pt-[10rem]"
      sectionClassNames="absolute z-10 top-0 left-auto right-auto"
    >
      <div/>
    </Section>
  );
};
