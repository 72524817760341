import Section from "../../../components/Section";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import tokenInformation from "../../../constants/tokenInformation";
import { signup } from "../../../urls";

const Prize = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section>
      <div
        className="flex flex-col items-center w-full mx-auto md:w-10/12"
        id="prize"
        ref={ref}
      >
        <h1
          className={twMerge(
            "mb-7 md:mb-14 transition duration-200 text-center",
            inView ? "animate-fade-down" : "opacity-0"
          )}
        >
          <span className="text-yellow">{tokenInformation.prizePool}</span>{" "}
          {t("prize_tile")}
        </h1>
        <div className="flex items-center gap-4 mb-14">
          <a
            href={'/give-away'}
            target="_blank"
            className="b_xs bg-[#E99138] hover:bg-[#d67818]"
            rel="noreferrer"
          >
            {t("join_giveaway")}
          </a>
          <a href={signup} target="_blank" className="b_xs" rel="noreferrer">
            {t("how_to_buy")}
          </a>
          <a
            href={signup}
            target="_blank"
            className="b_xs_outline"
            rel="noreferrer"
          >
            {t("join_presale")}
          </a>
        </div>

        <div className="grid w-full grid-cols-1 gap-10 lg:grid-cols-2">
          <div className="z-0 flex flex-col justify-center w-full md:p-5">
            <p className="mb-5 leading-none uppercase md:mb-10 t_section drop-shadow-c36px">
              {t("big_prize")}:
            </p>
            <p className="t_base mb-14">{t("prize_description_1")}</p>
            <h2 className="mb-5 uppercase md:mb-10">
              {t("weekly_giveaways")}:
            </h2>
            <p
              className="mb-10 t_base"
              dangerouslySetInnerHTML={{ __html: t("prize_description_2") }}
            />
          </div>

          <div className="hidden md:block">
            <img
              className={twMerge(
                "w-full object-contain transition duration-200 md:p-5 md:w-3/4 lg:w-full mx-auto",
                inView ? "animate-fade-left" : "opacity-0"
              )}
              src="/images/prize.png"
              alt="Prize"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Prize;
