import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import tokenInformation from "../../../constants/tokenInformation";
import { swal } from "../../../helpers/swal";

const Information = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section>
      <div
        ref={ref}
        className="flex flex-col w-full pb-5 md:pb-10 xl:pb-20 relative items-center"
      >
        <div className="w-full flex flex-col justify-center items-center gap-5 z-0 relative">
          <div className="w-full lg:w-7/12 mx-auto flex flex-col items-center">
            <p className="t_section text-center mb-4 drop-shadow-c36px">
              {t("tokenomics")}
            </p>
            <p className="t_2xl mb-12">{t("tokenomics_description")}</p>

            <div className="flex flex-col mb-14 w-full">
              <div className="flex items-center gap-2 bg-black/40 rounded-full">
                <div className="t_base truncate px-4 w-full">
                  {tokenInformation.address}
                </div>
                <div>
                  <button
                    className="md:w-[13rem] flex-1 b h-14 px-3 rounded-full flex items-center justify-center gap-3"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(tokenInformation.address)
                        .then(() => {
                          swal.fire({
                            title: "Copied to clipboard!",
                            timer: 2000,
                            icon: "success",
                            confirmButtonText: "Exit",
                          });
                        });
                    }}
                  >
                    <p className="t_base font-thin text-black line-clamp-1 normal-case  md:block">
                      {t("copy_address_btn")}
                    </p>
                    <img
                      className="object-contain h-4 w-4 aspect-auto"
                      src="/images/copy.svg"
                      alt="Copy"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card-border bg-[#202020]/50 p-4 lg:px-10 w-full flex flex-col items-center justify-center relative">
            <img
              src="/images/chart.svg"
              className="h-auto w-full object-contain mt-5"
              alt="Chart"
            />

            <div className="w-full md:w-11/12 lg:w-9/12 grid grid-cols-1 md:grid-cols-5 lg:divide-x-[1px] divide-yellow bg-dark_blue rounded-3xl border-yellow border-[2px] md:absolute md:bottom-0 md:translate-y-1/2">
              <div
                className={twJoin(
                  "px-6 py-4 gap-3 flex md:flex-col justify-between items-center md:justify-center md:items-start w-full",
                  inView && "animate-fade-left animate-duration-500"
                )}
              >
                <p className="font-semibold t_base line-clamp-1">
                  {t("symbol")}:
                </p>
                <img
                  src="/images/logo-short.png"
                  className="h-6 w-auto object-contain"
                  alt="Logo"
                />
              </div>
              <div
                className={twJoin(
                  "px-6 py-4 gap-4 flex md:flex-col justify-between items-center md:justify-center md:items-start w-full",
                  inView &&
                    "animate-fade-left animate-duration-500 animate-delay-150"
                )}
              >
                <p className="font-semibold t_base line-clamp-1">
                  {t("supply")}:
                </p>
                <p className="font-medium t_base line-clamp-1">
                  {tokenInformation.supply}
                </p>
              </div>
              <div
                className={twJoin(
                  "px-6 py-4 gap-4 flex md:flex-col justify-between items-center md:justify-center md:items-start w-full",
                  inView &&
                    "animate-fade-left animate-duration-500 animate-delay-300"
                )}
              >
                <p className="font-semibold t_base line-clamp-1">
                  {t("network")}:
                </p>
                <div className="flex items-center justify-between w-auto gap-4">
                  <p className="font-medium t_base line-clamp-1">ERC20</p>
                  <img className="w-auto h-5" src="/images/eth-1.png" alt="" />
                </div>
              </div>
              <div
                className={twJoin(
                  "px-6 py-4 gap-4 flex md:flex-col justify-between items-center md:justify-center md:items-start w-full",
                  inView &&
                    "animate-fade-left animate-duration-500 animate-delay-[450ms]"
                )}
              >
                <p className="font-semibold t_base line-clamp-1">
                  {t("buy_tax")}:
                </p>
                <div className="font-medium t_base line-clamp-1 flex items-center gap-4">
                  0%
                </div>
              </div>
              <div
                className={twJoin(
                  "px-6 py-4 gap-4 flex md:flex-col justify-between items-center md:justify-center md:items-start w-full",
                  inView &&
                    "animate-fade-left animate-duration-500 animate-delay-[600ms]"
                )}
              >
                <div className="font-semibold t_base line-clamp-1">
                  {t("sell_tax")}:
                </div>
                <div className="font-medium t_base line-clamp-1 flex items-center gap-4">
                  0%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Information;
