import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RootLayout from "./RootLayout";
import { Link, useLocation } from "react-router-dom";
import Drawer from "../Drawer";
import { twMerge } from "tailwind-merge";
import { dashboard, internal, login, signup, whitepaper } from "../../urls";
import { LanguageMenu } from "../LanguageMenu";
import "flag-icons/css/flag-icons.min.css";
import instance from "../../api";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  const [isShowingMenu, setShowingMenu] = useState(false);
  const [bonus, $bonus] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data }: { data: string } = await instance.get(
          "/bonuses/presale-current-bonus"
        );
        if (data && data !== "") {
          $bonus(data);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    })();
  }, []);

  const { pathname } = useLocation();

  const navigators = [
    {
      href: "/#about",
      name: t("about"),
    },
    {
      href: "/#eco-system",
      name: t("eco-system"),
    },
    {
      href: "/#roadmap",
      name: t("roadmap"),
    },
    {
      href: whitepaper,
      name: t("whitepaper"),
    },
  ];

  return (
    <RootLayout>
      <header className="fixed left-0 right-0 z-40 mx-auto bg_header duration-200">
        {bonus && (
          <div className="bg-yellow w-full p-1 items-center justify-center flex">
            <Link
              to={signup}
              className="t_base font-medium text-dark_blue text-center"
            >
              {bonus}
            </Link>
          </div>
        )}

        <Drawer open={isShowingMenu} onOpen={() => setShowingMenu(true)}>
          <div className="flex flex-col items-start gap-4 px-6 h-screen bg-black/80 backdrop-blur-md ">
            <div className="flex flex-row justify-between w-full mt-4">
              <h2>Menu</h2>

              <div onClick={() => setShowingMenu(false)}>
                <img
                  src="/images/icon_close.svg"
                  className="w-6 h-6 object-contain cursor-pointer"
                  alt="Close"
                />
              </div>
            </div>
            <Link
              to={internal.home}
              className={twMerge(
                "t_sm",
                pathname === "/" && "font-semibold text-yellow"
              )}
              onClick={() => {
                setShowingMenu(false);
                const buy = document.getElementById("buy-presale");
                if (buy) {
                  buy.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              {t("home")}
            </Link>

            {navigators.map((e, index) => {
              return (
                <a
                  key={index}
                  onClick={() => setShowingMenu(false)}
                  href={e.href}
                >
                  <p className="t_sm">{e.name}</p>
                </a>
              );
            })}

            <a href={dashboard} className="b_sm">
              {t("dashboard")}
            </a>
          </div>
        </Drawer>

        <div className="max-w-figma w-full mx-auto">
          <div className="py-4 px-7 lg:px-16 border-0 h-auto flex relative gap-2.5 items-center justify-between">
            <Link to={internal.home} className="flex items-center">
              <img
                className="object-contain h-[2.5rem] md:h-[3.75rem] w-auto aspect-auto"
                src="/images/logo.png"
                alt="Logo"
              />
            </Link>
            <div className="gap-2.5 hidden lg:flex w-full items-center justify-end">
              {navigators.map((e, index) => {
                return (
                  <a key={index} href={e.href} className="p-2.5 my-auto">
                    <p className="duration-200 t_xs uppercase tracking-[0.2rem] hover:text-yellow line-clamp-1">
                      {e.name}
                    </p>
                  </a>
                );
              })}

              <LanguageMenu />

              <a
                href={login}
                className="t_xs px-4"
                target="_blank"
                rel="noreferrer"
              >
                <span className="line-clamp-1">{t("login")}</span>
              </a>

              <a
                href={signup}
                className="b_xs"
                target="_blank"
                rel="noreferrer"
              >
                <span className="line-clamp-1">{t("buy_now")}</span>
              </a>
            </div>

            <div className="flex items-center lg:hidden">
              <LanguageMenu />
              <div onClick={() => setShowingMenu(true)}>
                <img
                  alt="Bar"
                  className="w-6 ml-4 cursor-pointer"
                  src="/images/icon_bar.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-2 bg-yellow w-full"></div>
      </header>
      <main className="relative overflow-x-hidden bg-dark_blue">
        {children}
      </main>
    </RootLayout>
  );
};

export default Layout;
