import React from "react";
import Mask from "../../components/Mask";
import Section from "../../components/Section";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const HoldToken = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();

  return (
    <Mask>
      <Section>
        <div className="relative flex items-center justify-center gap-2">
          <img
            src="images/give_away/give_away_meme_icon.png"
            alt=""
            className="w-20 h-auto md:w-40"
          />

          <h3 className="z-20 font-bold t_36px">
            {t("give_away_hold_token_title")}
          </h3>

          <img
            src="images/give_away_throw_money.png"
            alt=""
            className="top-0 w-auto h-20 -ml-16 md:h-40"
          />
        </div>

        <div className="flex flex-col items-center gap-7 pl-14" ref={ref}>
          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 w-full p-6 pl-16 duration-200 animate-delay-0",
              inView && "animate-fade-right"
            )}
          >
            <div className="absolute left-0 flex items-center justify-center px-6 py-4 font-bold -translate-x-1/2 -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 top-1/2 bg-dark_yellow text-dark_blue rounded-3xl t_36px">
              {t("tier_1")}
            </div>
            <span
              className="t_36px"
              dangerouslySetInnerHTML={{
                __html: t("give_away_hold_token_description_1"),
              }}
            />
          </div>

          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 border-[#00B8C4] w-full p-6 pl-16 duration-200 animate-delay-0",
              inView && "animate-fade-left"
            )}
          >
            <div className="absolute left-0 flex items-center justify-center md:w-48 md:py-6 py-4 w-fit px-6 md:px-0  -translate-x-1/2 -translate-y-1/2 top-1/2 bg-[#00B8C4] text-dark_blue rounded-3xl font-bold t_36px">
              {t("tier_2")}
            </div>
            <span
              className="t_36px"
              dangerouslySetInnerHTML={{
                __html: t("give_away_hold_token_description_2"),
              }}
            />
          </div>

          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 border-dark_purple w-full p-6 pl-16 duration-200 animate-delay-0",
              inView && "animate-fade-left"
            )}
          >
            <div className="absolute left-0 flex items-center justify-center px-6 py-4 font-bold -translate-x-1/2 -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 top-1/2 bg-dark_purple text-dark_blue rounded-3xl t_36px">
              {t("tier_3")}
            </div>
            <span
              className="t_36px"
              dangerouslySetInnerHTML={{
                __html: t("give_away_hold_token_description_3"),
              }}
            />
          </div>
        </div>
      </Section>
    </Mask>
  );
};
