import { useInView } from "react-intersection-observer";
import Section from "../../components/Section";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const SubmissionProcess = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  return (
    <Section className="flex flex-col items-center">
      <h3 className="font-bold t_48px">{t("give_away_submission_process")}</h3>

      <h4 className="font-bold text-dark_yellow t_36px">
        {t("give_away_submission_process_description")}
      </h4>

      <div ref={ref}>
        <div className="hidden w-full md:block">
          <img
            src="images/give_away/give_away_submission_process.png"
            alt=""
            className={twMerge(
              "w-full h-auto object-contain duration-200 animate-delay-0",
              inView && "animate-fade-left"
            )}
          />
        </div>

        <div className="flex flex-col items-center gap-12 mt-12 md:hidden">
          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 w-full max-w-5xl p-6 duration-200 animate-delay-0",
              inView && "animate-fade-left"
            )}
          >
            <div className="absolute top-0 left-[20px] flex items-center justify-center px-6 py-4 font-bold -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 bg-dark_yellow text-dark_blue rounded-3xl t_36px">
              #1
            </div>
            <span className="block mt-4 ">
              <b className="text-yellow t_36px">
                {t("give_away_submission_process_task_1_title")}
              </b>
              <br />
              {t("give_away_submission_process_task_1_description")}
            </span>
          </div>

          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 w-full max-w-5xl p-6 duration-200 animate-delay-0 border-[#00B8C4]",
              inView && "animate-fade-right"
            )}
          >
            <div className="absolute top-0 left-[20px] flex items-center justify-center px-6 py-4 font-bold -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 bg-[#00B8C4] text-dark_blue rounded-3xl t_36px">
              #2
            </div>
            <span className="block mt-4 ">
              <b className="text-[#00B8C4] t_36px">
                {t("give_away_submission_process_task_2_title")}
              </b>
              <br />
              {t("give_away_submission_process_task_2_description")}
            </span>
          </div>

          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 w-full max-w-5xl p-6 duration-200 animate-delay-0 border-[#8A67ED]",
              inView && "animate-fade-left"
            )}
          >
            <div className="absolute top-0 left-[20px] flex items-center justify-center px-6 py-4 font-bold -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 bg-[#8A67ED] text-dark_blue rounded-3xl t_36px">
              #3
            </div>
            <span className="block mt-4 ">
              <b className="text-[#8A67ED] t_36px">
                {t("give_away_submission_process_task_3_title")}
              </b>
              <br />
              {t("give_away_submission_process_task_3_description")}
            </span>
          </div>

          <div
            className={twMerge(
              "card-border border-[2px] relative md:p-12 md:pl-36 w-full max-w-5xl p-6 duration-200 animate-delay-0 border-[#D4F35F]",
              inView && "animate-fade-right"
            )}
          >
            <div className="absolute top-0 left-[20px] flex items-center justify-center px-6 py-4 font-bold -translate-y-1/2 md:w-48 md:py-6 w-fit md:px-0 bg-[#D4F35F] text-dark_blue rounded-3xl t_36px">
              #4
            </div>
            <span className="block mt-4 ">
              <b className="text-[#D4F35F] t_36px">
                {t("give_away_submission_process_task_4_title")}
              </b>
              <br />
              {t("give_away_submission_process_task_4_description")}
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};
