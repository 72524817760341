import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { solidProofAudit } from "../../../urls";

const Auditor = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section>
      <div className="w-full flex flex-col" id="audit">
        <div className="flex flex-col w-full gap-10 items-center" ref={ref}>
          <div className="w-full flex flex-col lg:flex-row justify-between gap-4">
            <div className="flex items-center justify-center w-full lg:w-5/12 lg:px-10 gap-5">
              <img
                alt="Lock"
                src="/images/audit-lock.png"
                className="h-auto w-10 md:w-16 object-contain"
              />
              <h2>{t("protection_description")}</h2>
            </div>

            <div className="hidden md:block v-line" />

            <div className="flex flex-col sm:flex-row items-center justify-evenly w-full lg:w-7/12 gap-4">
              <a href={solidProofAudit} target="_blank" rel="noreferrer">
                <img
                  alt="Audit"
                  src="/images/audit-1.png"
                  className={twMerge(
                    "h-12 w-auto object-contain duration-200 animate-delay-0",
                    inView && "animate-fade-left"
                  )}
                />
              </a>

             
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Auditor;
