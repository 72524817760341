import classNames from "classnames";
import { ReactNode } from "react";

export default function Drawer({
  children,
  open,
  onOpen,
}: {
  children: ReactNode;
  open: boolean;
  onOpen: Function;
}) {
  return (
    <main
      className={classNames(
        "fixed z-50 bg-gray-900 bg-opacity-25 inset-0 transform w-full h-full flex",
        open ? " block duration-500" : "hidden duration-500"
      )}
    >
      <div
        onClick={(event) => {
          event.preventDefault();
          onOpen();
        }}
        className="bg-black opacity-25 w-1/4 cursor-pointer"
      ></div>
      <section className={"flex-1 z-30"}>{children}</section>
    </main>
  );
}
