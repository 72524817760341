import { useTranslation } from "react-i18next";
import Section from "../../../components/Section";
import { twJoin } from "tailwind-merge";
import { useInView } from "react-intersection-observer";

const partners = [
  { logo: "images/0.png" },
  { logo: "images/1.png" },
  { logo: "images/2.png" },
  { logo: "images/3.png" },
  { logo: "images/4.png" },
];

const Partner = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section>
      <div
        className="w-full flex flex-col relative items-center justify-center rounded-3xl"
        ref={ref}
      >
        <h3
          className={twJoin(
            "t_lg tracking-[0.5rem] md:tracking-[0.75rem] uppercase mb-8 md:mb-14"
          )}
        >
          {t("partners_title")}
        </h3>

        <div className="grid grid-cols-3 md:grid-cols-5 w-full gap-x-[6%] gap-y-5 px-4">
          {partners.map((e, index) => (
            <div
              key={index}
              className={twJoin(
                "flex items-center justify-center duration-200",
                inView && "animate-fade-right",

                index === 0 && "animate-delay-0",
                index === 1 && "animate-delay-100",
                index === 2 && "animate-delay-200",
                index === 3 && "animate-delay-300",
                index === 4 && "animate-delay-[400ms]"
              )}
            >
              <img
                src={partners[index].logo}
                className="w-auto h-auto max-h-[2.5rem] md:max-h-[3.75rem]"
                alt="Logo"
              />
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Partner;
