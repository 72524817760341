import Layout from "../components/Layouts/Layout";
import Mask from "../components/Mask";
import Section from "../components/Section";
import { twitter, discord, telegram, instagram } from "../urls";
import classNames from "classnames";
import { SubmissionProcess } from "./give-away/SubmissionProcess";
import { Footer } from "../components/Footer";
import { Banner } from "./give-away/Banner";
import { Summary } from "./give-away/Summary";
import { HoldToken } from "./give-away/HoldToken";
import { Format } from "./give-away/Format";
import { useTranslation } from "react-i18next";

const GiveAway = () => {
  const { t } = useTranslation();

  const socials = [
    {
      icon: "images/x.svg",
      link: twitter,
      text: t("give_away_social_twitter"),
      wrapperClass: "border-[#A2A2A2]",
    },
    {
      icon: "images/discord.svg",
      link: discord,
      text: t("give_away_social_discord"),
      wrapperClass: "border-[#8A67ED]",
    },
    {
      icon: "images/telegram.svg",
      link: telegram,
      text: t("give_away_social_telegram"),
      wrapperClass: "border-[#00B8C4]",
    },
    {
      icon: "images/ins.svg",
      link: instagram,
      text: t("give_away_social_instagram"),
      wrapperClass: "border-[#F864C3]",
    },
  ];
  return (
    <Layout>
      <Banner />
      <Summary />

      <HoldToken />
      <Mask
        image="images/give_away/give_away-wall-street.png"
        maskClassNames="-mt-[250px]"
      >
        <Section className="flex flex-col items-center">
          <h3 className="font-bold t_36px">{t("give_away_social_title")}</h3>

          <div className="grid w-full grid-cols-1 gap-4 mt-12 lg:grid-cols-4 sm:grid-cols-2">
            {socials.map((social) => (
              <a
                href={social.link}
                className={classNames(
                  "flex items-center py-4 px-5 border-[2px] rounded-2xl w-full gap-4 md:gap-6",
                  social.wrapperClass
                )}
                target="_blank"
                rel="noreferrer"
              >
                <div className="bg-[#474747] rounded-lg p-2 drop-shadow-xl flex-shrink-0">
                  <img
                    src={social.icon}
                    className="object-fill w-4 h-4 md:w-6 md:h-6"
                    alt=""
                  />
                </div>

                <span>{social.text}</span>
              </a>
            ))}
          </div>
        </Section>
        <SubmissionProcess />
      </Mask>

      <Mask
        bottomSeparator
        image="images/give_away/give_away-wall-street-2.png"
        maskClassNames="-mt-[300px]"
      >
        <Section className="z-10">
          <div
            className="relative h-auto px-4 py-6 md:py-10 md:px-7"
            style={{
              backgroundImage:
                'url("images/give_away/engagement_background.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div>
              <h3 className="t_48px">
                {t("give_away_engagement_criteria_title")}
              </h3>
              <h4 className="font-bold t_36px text-dark_yellow">
                {t("give_away_engagement_criteria_description")}
              </h4>
            </div>

            <img
              src="images/give_away/meme_icon.png"
              className="absolute -top-14 right-0 object-fill md:w-[242px] h-auto w-[180px] hidden sm:block"
              alt=""
            />

            <div className="flex flex-col gap-4 mt-10">
              <div className="border-[2px] md:gap-10 gap-4 border-dark_yellow md:p-5 flex items-center rounded-full p-3">
                <div className="flex-none w-5 h-5 rounded-full md:w-9 md:h-9 bg-dark_yellow drop-shadow-cxl" />
                <span
                  className="text-base t_36px"
                  dangerouslySetInnerHTML={{
                    __html: t("give_away_engagement_criteria_description_1"),
                  }}
                />
              </div>

              <div className="border-[2px] md:gap-10 gap-4 border-[#00B8C4] md:p-5 flex items-center rounded-full p-3">
                <div className="rounded-full  md:w-9 md:h-9 w-5 h-5 bg-[#00B8C4] drop-shadow-cxl flex-none" />
                <span
                  className="text-base t_36px"
                  dangerouslySetInnerHTML={{
                    __html: t("give_away_engagement_criteria_description_2"),
                  }}
                />
              </div>

              <div className="border-[2px] md:gap-10 gap-4 border-dark_purple md:p-5 flex items-center rounded-full p-3">
                <div className="flex-none w-5 h-5 rounded-full md:w-9 md:h-9 bg-dark_purple drop-shadow-cxl" />
                <span
                  className="text-base t_36px"
                  dangerouslySetInnerHTML={{
                    __html: t("give_away_engagement_criteria_description_3", {
                      twitter,
                    }),
                  }}
                />
              </div>
            </div>
          </div>
        </Section>
        <Format />
      </Mask>

      <Mask
        image="images/give_away/give_away-wall-street-3.png"
        maskClassNames="-mt-[250px]"
      >
        <div className="h-line-wrapper">
          <div className="h-line bg-dark_yellow" />
        </div>
        <Section className="flex flex-col items-center">
          <h3 className="font-bold t_36px">
            {t("give_away_term_and_condition_apply")}
          </h3>

          <h4 className="mt-4">
            {t("give_away_term_and_condition_description_1")}
          </h4>

          <div className="card-border bg-[#202020]  flex flex-col items-center t_36px font-bold px-8 py-6 md:px-24 md:py-8 text-center lg:w-2/3 mt-8">
            <span className="font-extrabold">
              {t("give_away_term_and_condition_description_2")}
            </span>
            <span className="text-dark_yellow">
              {t("give_away_term_and_condition_description_3")}
            </span>
          </div>
        </Section>
      </Mask>

      <Mask bottomSeparator topSeparator>
        <Footer />
      </Mask>
    </Layout>
  );
};

export default GiveAway;
