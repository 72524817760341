import { useTranslation } from "react-i18next";
import { internal, socials, whitepaper } from "../urls";
import Section from "./Section";

export const Footer = () => {
  const { t } = useTranslation();
  const navigation = [
    { label: t("how_to_buy") },
    { label: "Whitepaper", href: whitepaper },
    { label: "Team", href: "#team" },
    { label: t("join_our_presale") },
    { label: t("terms_conditions"), href: internal.terms_conditions },
    { label: t("privacy_policy"), href: internal.privacy_policy },
    { label: t("cookie_policy"), href: internal.cookie_policy },
  ];

  return (
    <Section>
      <div className="h-full w-full relative overflow-hidden">
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-20 lg:gap-40">
          <div className="flex flex-col order-2 lg:order-1">
            <p className="t_2xl">Navigation</p>
            <div className="h-line my-6" />
            {navigation.map((e, index) => (
              <a
                key={index}
                href={e.href ?? "#"}
                className="t_base mb-4 hover:text-yellow duration-200"
              >
                {e.label}
              </a>
            ))}
          </div>

          <div className="flex flex-col order-3 lg:order-2">
            <p className="t_2xl">Our Socials</p>
            <div className="h-line my-6" />
            <div className="flex items-center mt-4 mb-14 gap-8">
              {socials.map((e, index) => (
                <a
                  href={e.link}
                  target="_blank"
                  key={index}
                  className="w-10 h-10 bg-dark_blue rounded-xl shadow-xl flex items-center justify-center"
                  rel="noreferrer"
                >
                  <img
                    alt="Social"
                    src={e.icon}
                    className="h-auto w-7 object-contain"
                  />
                </a>
              ))}
            </div>

            <p className="t_2xl">Contact Us</p>
            <div className="h-line my-6" />
            <a
              href="mailto:contact@mememoguls.com"
              className="t_base mb-4 hover:text-yellow duration-200"
            >
              Information: contact@mememoguls.com
            </a>
            <a
              href="mailto:support@mememoguls.com"
              className="t_base mb-4 hover:text-yellow duration-200"
            >
              Help: support@mememoguls.com
            </a>
          </div>

          <div className="order-1 lg:order-3">
            <a href={"#buy-presale"}>
              <img
                className="h-[5rem] md:h-[7.5rem] w-auto object-contain"
                src="/images/footer-logo.png"
                alt="Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};
