import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { signup } from "../../../urls";
import { Fragment } from "react";

const Tax = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const leftCategories = [
    {
      title: t("tax_title_1"),
      description: t("tax_description_1"),
    },
    {
      title: t("tax_title_2"),
      description: t("tax_description_2"),
    },
    {
      title: t("tax_title_3"),
      description: t("tax_description_3"),
    },
  ];

  const rightCategories = [
    {
      title: t("tax_title_4"),
      description: t("tax_description_4"),
    },
    {
      title: t("tax_title_5"),
      description: t("tax_description_5"),
    },
    {
      title: t("tax_title_6"),
      description: t("tax_description_6"),
    },
  ];

  return (
    <Section>
      <div
        ref={ref}
        className="flex flex-col w-full pb-5 md:pb-10 xl:pb-20 relative items-center"
      >
        <div className="w-full flex flex-col justify-center items-center gap-5 z-0 relative">
          <div className="card-border h-auto bg-[#202020] p-5 md:p-10 w-full md:w-4/5 flex flex-col items-center relative">
            <div className="z-10 flex flex-col items-center">
              <h2 className="mb-5">Meme Moguls: MGLS</h2>
              <h1 className="text-yellow text-center mb-10 md:mb-20">
                {t("tax_description")}
              </h1>

              <div className="grid grid-cols-1 lg:grid-cols-8">
                <div className="lg:col-span-3 order-2 lg:order-1">
                  {leftCategories.map((e, index) => (
                    <Fragment key={index}>
                      <div
                        className={twMerge(
                          "flex items-center my-4 md:my-8 gap-5 duration-200",
                          inView && "animate-fade-right",

                          index === 0 && "animate-delay-0",
                          index === 1 && "animate-delay-100",
                          index === 2 && "animate-delay-200"
                        )}
                      >
                        <img
                          src="/images/tax-icon.png"
                          alt="Mask"
                          className="w-14 h-14 object-contain drop-shadow-cxl"
                        />
                        <div>
                          <p className="t_base font-semibold text-yellow">
                            {e.title}:
                          </p>
                          <p className="t_base">{e.description}</p>
                        </div>
                      </div>
                      {index < leftCategories.length - 1 && (
                        <div className="h-line" />
                      )}
                    </Fragment>
                  ))}
                </div>

                <div className="flex items-start justify-center lg:col-span-2 order-first lg:order-2">
                  <img
                    src="/images/big-coin.png"
                    alt="Mask"
                    className="w-full h-auto object-contain lg:scale-125"
                  />
                </div>

                <div className="lg:col-span-3 order-3">
                  {rightCategories.map((e, index) => (
                    <Fragment key={index}>
                      <div
                        className={twMerge(
                          "flex items-center my-4 md:my-8 gap-5 duration-200",
                          inView && "animate-fade-left",

                          index === 0 && "animate-delay-300",
                          index === 1 && "animate-delay-[400ms]",
                          index === 2 && "animate-delay-500"
                        )}
                      >
                        <img
                          src="/images/tax-icon.png"
                          alt="Mask"
                          className="w-14 h-14 object-contain drop-shadow-cxl"
                        />
                        <div>
                          <p className="t_base font-semibold text-yellow">
                            {e.title}:
                          </p>
                          <p className="t_base">{e.description}</p>
                        </div>
                      </div>
                      {index < rightCategories.length - 1 && (
                        <div className="h-line" />
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>

            <div className="z-10 mb-40">
              <a
                className="b_xs"
                href={signup}
                target="_blank"
                rel="noreferrer"
              >
                {t("buy_now")}
              </a>
            </div>

            <div className="absolute bottom-0 inset-x-0 w-full h-auto pointer-events-none z-0">
              <img
                src="/images/mask-6.png"
                alt="Mask"
                className="w-full h-auto object-contain"
              />
            </div>

            <img
              src="/images/tax-sticker-1.png"
              alt="Sticker"
              className="max-w-[40%] w-auto h-auto object-contain z-0 absolute -bottom-1 -left-1"
            />

            <img
              src="/images/tax-sticker-2.png"
              alt="Sticker"
              className="max-w-[40%] w-auto h-auto object-contain z-0 absolute -bottom-1 -right-1"
            />

            <img
              src="/images/tax-sticker-4.png"
              alt="Sticker"
              className="max-w-[30%] w-auto h-auto object-contain absolute top-0 left-0 -translate-y-[60%] translate-x-1/2 -z-10"
            />

            <img
              src="/images/tax-sticker-3.png"
              alt="Sticker"
              className="max-w-[30%] w-auto h-auto object-contain z-0 absolute bottom-0 right-0 translate-y-1/3 -translate-x-1/2"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Tax;
