import { useMemo } from "react";
import Section from "../../../components/Section";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

const Team = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const teams = useMemo(
    () => [
      {
        name: `Maximus`,
        role: "Game Architect",
        avatar: "/images/team-0.png",
      },
      {
        name: `Maverick`,
        role: t("role_1"),
        avatar: "/images/team-1.png",
      },
      {
        name: `Morgan`,
        role: t("role_2"),
        avatar: "/images/team-2.png",
      },
      {
        name: `Magnus`,
        role: t("role_3"),
        avatar: "/images/team-3.png",
      },
    ],
    [t]
  );

  return (
    <Section>
      <div
        className="w-full flex flex-col gap-12 relative md:w-10/12 mx-auto"
        ref={ref}
        id="team"
      >
        <div className="flex flex-col relative">
          <h1>{t("the_team_title")}</h1>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-10">
          {teams.map((e, index) => (
            <div
              key={index}
              className={twJoin(
                "flex flex-col items-center justify-start w-full",
                inView && "animate-fade-right",

                index === 0 && "animate-delay-0",
                index === 1 && "animate-delay-100",
                index === 2 && "animate-delay-200",
                index === 3 && "animate-delay-300"
              )}
            >
              <div className="flex flex-col w-full bg-yellow drop-shadow-xl card-rounded mb-8">
                <img
                  src={e.avatar}
                  className="w-auto h-auto object-contain"
                  alt="Team"
                />
              </div>
              <div className="rounded-3xl w-full gap-1.5 items-start justify-center flex flex-col">
                <div className="t_2xl normal-case font-medium">{e.name}</div>
                <div className="t_base text-dark_gray">{e.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Team;
