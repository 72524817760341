import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en/translation.json";
import saTranslation from "./locales/sa/translation.json";
import deTranslation from "./locales/de/translation.json";
import idTranslation from "./locales/id/translation.json";
import itTranslation from "./locales/it/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import ruTranslation from "./locales/ru/translation.json";
import frTranslation from "./locales/fr/translation.json";
import trTranslation from "./locales/tr/translation.json";
import cnTranslation from "./locales/cn/translation.json";
import krTranslation from "./locales/kr/translation.json";
import vnTranslation from "./locales/vn/translation.json";
import thTranslation from "./locales/th/translation.json";
import skTranslation from "./locales/sk/translation.json";
import jpTranslation from "./locales/jp/translation.json";
import hiTranslation from "./locales/hi/translation.json";
import czTranslation from "./locales/cz/translation.json";
import bgTranslation from "./locales/bg/translation.json";
import huTranslation from "./locales/hu/translation.json";
import esTranslation from "./locales/es/translation.json";
import plTranslation from "./locales/pl/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      gb: {
        translation: enTranslation,
      },
      sa: {
        translation: saTranslation,
      },
      cn: {
        translation: cnTranslation,
      },
      de: {
        translation: deTranslation,
      },
      id: {
        translation: idTranslation,
      },
      it: {
        translation: itTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
      ru: {
        translation: ruTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      tr: {
        translation: trTranslation,
      },
      kr: {
        translation: krTranslation,
      },
      vn: {
        translation: vnTranslation,
      },
      th: {
        translation: thTranslation,
      },
      sk: {
        translation: skTranslation,
      },
      jp: {
        translation: jpTranslation,
      },
      in: {
        translation: hiTranslation,
      },
      cz: {
        translation: czTranslation,
      },
      bg: {
        translation: bgTranslation,
      },
      hu: {
        translation: huTranslation,
      },
      es: {
        translation: esTranslation,
      },
      pl: {
        translation: plTranslation,
      },
    },
    lng: localStorage.getItem("language") ?? "gb",
    fallbackLng: "gb",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
