import { useInView } from "react-intersection-observer";
import Section from "../../../components/Section";
import { twJoin } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { signup } from "../../../urls";

export const Labels = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <Section sectionClassNames="z-10 top-0 left-auto right-auto">
      <div id="welcome" className="flex flex-col" ref={ref}>
        <div className="flex flex-col items-center justify-center w-full relative">
          <h1
            className={twJoin(
              "text-center duration-200 mb-2 uppercase",
              inView && "animate-fade-down"
            )}
          >
            {t("banner_title_1")}
          </h1>
          <div
            className={twJoin(
              "t_lg text-center duration-100 animate-delay-100 md:w-8/12 mb-5",
              inView && "animate-fade-down"
            )}
            dangerouslySetInnerHTML={{ __html: t("banner_description") }}
          ></div>

          <div className="flex items-center gap-4">
            <a
              href={signup}
              target="_blank"
              className={twJoin(
                "b_lg duration-200 animate-delay-200",
                inView && "animate-fade-down"
              )}
              rel="noreferrer"
            >
              {t("buy_now")}
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
};
