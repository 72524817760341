import { Banner } from "./components/Banner";
import Layout from "../../components/Layouts/Layout";
import Guide from "./components/Guide";
import Partner from "./components/Partner";
import Information from "./components/Information";
import Team from "./components/Team";
import FAQs from "./components/FAQs";
import Auditor from "./components/Auditor";
import Roadmap from "./components/Roadmap";
import { Footer } from "../../components/Footer";
import { TradeAlert } from "../../components/TradeAlert";
import Challenges from "./components/Challenges";
import Mask from "../../components/Mask";
import Prize from "./components/Prize";
import Tax from "./components/Tax";
import News from "./components/News";
import GameFeatures from "./components/GameFeatures";
import StageProcess from "./components/StageProcess";
import { Labels } from "./components/Labels";

const Home = () => {
  return (
    <Layout>
      <Mask bottomSeparator>
        <img
          src="images/banner.png"
          alt="Mask"
          className="mask-image h-full max-h-[100vh] hidden md:block object-cover min-h-[40rem] object-bottom"
        />
        <img
          src="images/banner-mobile.png"
          alt="Mask"
          className="mask-image block md:hidden max-h-[80vh] object-cover min-h-[32rem] object-bottom"
        />
        <Banner />
        <StageProcess />
      </Mask>

      <Mask bottomSeparator>
        <Partner />
      </Mask>

      <Mask image="images/mask-3.png" bottomSeparator>
        <Challenges />
      </Mask>

      <Mask image="images/mask-7.png" bottomSeparator maskClassNames="top-auto">
        <GameFeatures />
      </Mask>

      <Mask bottomSeparator>
        <News />
      </Mask>

      <Mask image="images/mask-5.png" bottomSeparator>
        <Guide />
      </Mask>

      <Mask bottomSeparator>
        <Tax />
      </Mask>

      <Mask image="images/mask-1.png" bottomSeparator>
        <Information />
      </Mask>

      <Mask bottomSeparator>
        <Auditor />
      </Mask>

      <Mask bottomSeparator image="images/mask-2.png">
        <Roadmap />
      </Mask>

      <Mask bottomSeparator>
        <Team />
      </Mask>

      <Mask bottomSeparator image="images/mask-1.png">
        <FAQs />
      </Mask>

      <Mask bottomSeparator image="images/mask-4.png">
        <Prize />
      </Mask>

      <Mask bottomSeparator>
        <Footer />
      </Mask>

      <TradeAlert />
    </Layout>
  );
};

export default Home;
