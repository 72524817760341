import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../../components/Section";
import { dextools, socials, uniswap } from "../../../urls";
import instance from "../../../api";
import { Main } from "../../../types";
import { Labels } from "./Labels";
import { twJoin } from "tailwind-merge";

const StageProcess = () => {
  const { t } = useTranslation();
  const [main, $main] = useState<Main | undefined>(() => {
    const cache = localStorage.getItem("@processCache");
    if (cache) {
      return JSON.parse(cache);
    }
    return undefined;
  });

  useEffect(() => {
    (async () => {
      try {
        const { data }: { data: Main } = await instance.get("/main");
        if (data) {
          $main(data);
          localStorage.setItem("@processCache", JSON.stringify(data));
        }
      } catch (error: any) {
        console.log(error.message);
      }
    })();
  }, []);

  return (
    <Section className="-translate-y-1/3 lg:-translate-y-1/2 md:pb-0 -mb-32 lg:-mb-72">
      {/* <div
        id="community"
        className="mx-auto max-w-[48.25rem] w-full z-10 bg-[#3D3D3D] shadow-2xl card-rounded relative p-5 md:p-10 mb-14"
      >
        <div className="flex items-center justify-between w-full mb-5">
          <div className="flex flex-col sm:flex-row md:items-center gap-2">
            <p className="t_lg">{t("current_price")}:</p>
            <span className="t_lg font-semibold px-2 py-1 rounded-lg border border-yellow w-fit">
              ${Number(main?.currentTokenPrice || 0)}
            </span>
          </div>

          <div className="flex flex-col sm:flex-row md:items-center gap-2">
            <p className="t_lg">USDT Raised:</p>

            <span className="t_lg font-semibold px-2 py-1 rounded-lg border border-yellow">
              <CountUp
                start={0}
                end={main?.totalRaisedUsd ?? 0}
                duration={2}
                prefix="$"
              />
            </span>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="progress-block__strip h-12 rounded-full z-20">
            <div
              className="progress-block__strip-inner h-full left-0 right-0 rounded-full w-full min-w-[15px] !overflow-visible"
              style={{
                width: `${Number(main?.percentToken ?? 0)}%`,
              }}
            >
              <div className="progress-block__percent h-full w-full relative rounded-full bg-gradient-to-r from-dark_yellow/20 via-yellow/20 to-yellow/20">
                <div className="bg-transparent h-full w-auto aspect-square absolute right-0 rounded-full flex items-center justify-center scale-[1.75]">
                  <img
                    src="images/coin-avatar.png"
                    alt="Coin"
                    className="w-full h-full object-contain z-10 scale-[1.75] drop-shadow-xl bg-transparent scalable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-max max-w-full h-max justify-center mt-6 border-b pb-4 px-1 border-b-[#737373] mx-auto">
          <Countdown
            date={moment(main?.stageEndTime).toDate()}
            renderer={({ days, hours, minutes, seconds }) => {
              return (
                <div className="flex gap-1 md:gap-3 flex-wrap">
                  <TimerTemplate value={`${days}`} title={t("days")} />
                  <div className="timer h-12 md:h-14 flex items-center t_40px">
                    {" "}
                    :{" "}
                  </div>
                  <TimerTemplate value={`${hours}`} title={t("hours")} />
                  <div className="timer h-12 md:h-14 flex items-center t_40px">
                    {" "}
                    :{" "}
                  </div>
                  <TimerTemplate value={`${minutes}`} title={t("minutes")} />
                  <div className="timer h-12 md:h-14 flex items-center t_40px">
                    {" "}
                    :{" "}
                  </div>
                  <TimerTemplate value={`${seconds}`} title={t("seconds")} />
                </div>
              );
            }}
          />
        </div>

        <div className="flex items-center justify-center gap-2 mt-5 w-full">
          <p className="t_lg text-center">
            {main?.stageName || "Stage"}: {t("only")}{" "}
            <span className="t_lg font-semibold px-2 py-1 rounded-lg border border-yellow">
              <CountUp
                start={0}
                end={main?.totalTokensLeftByStage ?? 0}
                duration={2}
              />
            </span>{" "}
            {main?.isLastStage
              ? t("last_stage_remain_description")
              : t("remain_description")}
          </p>
        </div>
      </div>  */}

      <div className="flex items-center gap-4 mx-auto mb-20">
        <a
          href={uniswap}
          target="_blank"
          className={twJoin(
            "b_lg duration-200 animate-delay-200 bg-[#FF007A] hover:bg-[#ff2e92]"
          )}
          rel="noreferrer"
        >
          Uniswap
        </a>

        <a
          href={dextools}
          target="_blank"
          className={twJoin(
            "b_lg duration-200 animate-delay-200 bg-[#00B8C4] hover:bg-[#00d0de]"
          )}
          rel="noreferrer"
        >
          Dextools
        </a>
      </div>

      <div className="flex flex-col md:flex-row items-center mx-auto gap-8">
        <div
          className="t_xs"
          dangerouslySetInnerHTML={{ __html: t("community_label") }}
        />
        <div className="flex items-center gap-8">
          {socials.map((e, index) => (
            <a
              href={e.link}
              target="_blank"
              key={index}
              className="w-10 h-10 bg-dark_blue rounded-xl shadow-xl flex items-center justify-center"
              rel="noreferrer"
            >
              <img
                alt="Social"
                src={e.icon}
                className="h-auto w-7 object-contain"
              />
            </a>
          ))}
        </div>
      </div>
      <Labels />
    </Section>
  );
};

// const TimerTemplate = ({ value, title }: { value: string; title: string }) => (
//   <div className="flex flex-col items-center">
//     <div className="flex h-14 min-w-[2.5rem] md:min-w-[4rem] bg-gradient-to-tr from-[#F6D878] via-[#2D2D2D] to-[#2D2D2D] rounded p-[1px]">
//       <div className="t_40px font-semibold w-full h-full px-2 bg-[#2D2D2D] rounded flex items-center justify-center">
//         <p className="timer drop-shadow-[0_1.1px_1.1px_#F6D878]">{value}</p>
//       </div>
//     </div>
//     <div className="hidden sm:flex uppercase t_sm text-[#F6D878] leading-7 mt-1 font-light tracking-wide">
//       {title}
//     </div>
//   </div>
// );

export default StageProcess;
