export const dashboard = "https://app.mememoguls.com/login";
export const telegram = "https://t.me/meme_moguls";
export const twitter = "https://twitter.com/meme_moguls";
export const discord = "https://discord.gg/mememoguls";
export const instagram = "https://www.instagram.com/meme_moguls";
export const whitepaper =
  "https://meme-moguls.gitbook.io/meme-moguls-usdmgls-whitepaper/";
export const login = "https://app.mememoguls.com/login";
export const signup = "https://app.mememoguls.com/sign-up";

export const solidProofAudit =
  "https://app.solidproof.io/projects/meme-moguls?audit_id=778";

export const uniswap = "https://app.uniswap.org/swap?outputCurrency=0xdA251891E21e6EDB0E6828E77621C7b98ea4E8ba&chain=ethereum"
export const dextools = "https://dexscreener.com/ethereum/0xc7ecab5bcf8866e18e4e84504eb3a633f3ef9128"


export const internal = {
  home: "/",
  privacy_policy: "/privacy-policy",
  terms_conditions: "/terms-n-conditions",
  cookie_policy: "/cookie-policy",
  give_away: "/give-away",
};

export const socials = [
  { icon: "images/x.svg", link: twitter },
  { icon: "images/discord.svg", link: discord },
  { icon: "images/telegram.svg", link: telegram },
  { icon: "images/ins.svg", link: instagram },
];
