import Home from "./pages/home";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { internal } from "./urls";
import TermsConditions from "./pages/TermsConditions";
import CookiePolicy from "./pages/CookiePolicy";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getUserIPData } from "./api/ipapi";
import { Link } from "react-router-dom";
import { getGeoUserIPData } from "./api/geoapi";
import GiveAway from "./pages/GiveAway";
import { useTranslation } from "react-i18next";
import { languages } from "./components/LanguageMenu";

const router = createBrowserRouter(
  [
    {
      path: internal.home,
      element: <Home />,
    },
    {
      path: internal.privacy_policy,
      element: <PrivacyPolicy />,
    },
    {
      path: internal.terms_conditions,
      element: <TermsConditions />,
    },
    {
      path: internal.cookie_policy,
      element: <CookiePolicy />,
    },
    {
      path: internal.give_away,
      element: <GiveAway />,
    },
    {
      path: "/*",
      element: <Home />,
    },
  ],
  {}
);

function App() {
  const [isShowingRestrictedModal, $isShowingRestrictedModal] = useState(false);
  const { i18n } = useTranslation();

  function closeModal() {
    $isShowingRestrictedModal(false);
  }
  function openModal() {
    $isShowingRestrictedModal(true);
  }

  useEffect(() => {
    (async () => {
      console.log("Fetch country")
      let country = localStorage.getItem("@userip");
      if (country) {
        try {
          const { data } = await getGeoUserIPData();
          if (data?.["IPv4"] === JSON.parse(country)?.["ip"]) {
            if (JSON.parse(country)?.["country_name"] === "United Kingdom") {
              openModal();
              return;
            }
            return;
          } 
        } catch (error) {}
      }

      try {
        const { data } = await getUserIPData();
        if (data?.country_code) {
          const targetLanguage = languages.find(e => e.key === `${data?.country_code}`.toLowerCase())
          if (targetLanguage) {
            localStorage.setItem("language", targetLanguage.key);
            await i18n.changeLanguage(targetLanguage.key);
          }
        } 
        localStorage.setItem("@userip", JSON.stringify(data));
        if (data?.country_name === "United Kingdom") {
          openModal();
        }
      } catch (error) {}
    })();
  }, [i18n]);

  return (
    <>
      <RouterProvider router={router} />

      <Transition appear show={isShowingRestrictedModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl bg-dark_blue">
                  <Dialog.Title as="h3" className="mb-3 font-semibold t_xl">
                    Website Disclaimer
                  </Dialog.Title>

                  <div className="t_sm">
                    This Website is not intended for a UK audience and does not
                    adhere to the UK Financial Promotions Regime. If you are
                    accessing this Website from the UK, please exit this site
                    immediately.
                  </div>

                  <div className="flex flex-wrap items-center gap-2 mt-4">
                    <a href={"https://www.google.com"} className="b_xs_outline">
                      Leave this site
                    </a>

                    <button type="button" className="b_xs" onClick={closeModal}>
                      Continue to site
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default App;
