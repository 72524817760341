import { useTranslation } from "react-i18next";
import Section from "./Section";

export const TradeAlert = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <div className="h-full w-full mx-auto relative overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-12 mb-5">
          <div className="flex flex-col gap-5">
            <div className="grid grid-cols-[1.25rem_1fr] gap-5">
              <div className="mt-0.5 flex bg-yellow w-5 h-5 rounded-full justify-center items-center">
                <p className="t_sm text-dark_blue">1</p>
              </div>
              <p className="t_sm leading-6">{t("trade_alert_description_1")}</p>
            </div>
            <div className="h-[1px] w-full bg-white/30"></div>
            <div className="grid grid-cols-[1.25rem_1fr] gap-5">
              <div className="mt-0.5 flex bg-yellow w-5 h-5 rounded-full justify-center items-center">
                <p className="t_sm text-dark_blue">2</p>
              </div>
              <p className="t_sm leading-6">{t("trade_alert_description_2")}</p>
            </div>
            <div className="h-[1px] w-full bg-white/30 md:hidden"></div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-[1.25rem_1fr] gap-5">
              <div className="mt-0.5 flex bg-yellow w-5 h-5 rounded-full justify-center items-center">
                <p className="t_sm text-dark_blue">3</p>
              </div>
              <p
                className="t_sm leading-6"
                dangerouslySetInnerHTML={{
                  __html: t("trade_alert_description_3"),
                }}
              ></p>
            </div>
            <div className="h-[1px] w-full bg-white/30"></div>
            <div className="grid grid-cols-[1.25rem_1fr] gap-5">
              <div className="mt-0.5 flex bg-yellow w-5 h-5 rounded-full justify-center items-center">
                <p className="t_sm text-dark_blue">4</p>
              </div>
              <p className="t_sm leading-6">{t("trade_alert_description_4")}</p>
            </div>
            <div className="h-[1px] w-full bg-white/30 md:hidden"></div>
          </div>
        </div>
      </div>
    </Section>
  );
};
