import React from "react";
import Mask from "../../components/Mask";
import Section from "../../components/Section";
import { useInView } from "react-intersection-observer";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const Summary = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  return (
    <Mask image="images/give_away/give_away_stock_mask.png">
      <Section className="pt-0 md:-mt-20 md:pt-0">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2" ref={ref}>
          <div
            className={twMerge(
              "card-border bg-[#202020] p-4 md:p-8 text-base flex flex-col justify-between duration-200 animate-delay-0",
              inView && "animate-fade-right"
            )}
          >
            <span
              className="block"
              dangerouslySetInnerHTML={{ __html: t("give_away_summary_1") }}
            ></span>

            <span className="block mt-5 md:mt-0">
              This comes as a special bonus, as we were not able to hold our
              Stage 1 competition. It sold out so quickly that we were not able
              to get it live in time!
            </span>
          </div>
          <div
            className={twMerge(
              "card-border bg-[#202020] p-4 md:p-8 ",
              inView && "animate-fade-left"
            )}
          >
            <span
              className="block"
              dangerouslySetInnerHTML={{ __html: t("give_away_summary_3") }}
            />

            <span className="block mt-5 t_2xl text-dark_yellow">
              {t("give_away_summary_4")}
            </span>

            <span
              className="block mt-5 "
              dangerouslySetInnerHTML={{ __html: t("give_away_summary_5") }}
            />
          </div>
        </div>
      </Section>
      <Section className="flex flex-col items-center border-t border-b border-dark_yellow">
        <h2>{t("give_away_time_title")}</h2>
        <span className="mt-3">{t("give_away_time_description")}</span>

        <b className="mt-5">{t("give_away_time_note")}</b>
      </Section>
      <Section className="flex flex-col items-center">
        <h2>{t("give_duration_title")}</h2>

        <div className="flex gap-4 mt-2 sm:gap-16 md:gap-40">
          <div className="flex flex-col ">
            <div className="bg-[#202020]/50 p-4 rounded-3xl flex items-center justify-center">
              <img
                src="images/give_away_calendar_icon.png"
                alt="Mask"
                className={twMerge(
                  "w-28 md:w-[160px] h-auto duration-200 animate-delay-0",
                  inView && "animate-fade-right"
                )}
              />
            </div>
            <span
              className="mt-12 font-medium text-center t_2xl font-inter"
              dangerouslySetInnerHTML={{ __html: t("give_away_active_date") }}
            />
          </div>

          <div className="flex flex-col ">
            <div className="bg-[#202020]/50 p-4 rounded-3xl flex items-center justify-center">
              <img
                src="images/give_away_trophy_icon.png"
                alt="Mask"
                className={twMerge(
                  "w-28 md:w-[160px] h-auto duration-200 animate-delay-0",
                  inView && "animate-fade-left"
                )}
              />
            </div>
            <span
              className="mt-12 font-medium text-center t_2xl font-inter"
              dangerouslySetInnerHTML={{
                __html: t("give_away_entry_criteria"),
              }}
            />
          </div>
        </div>
      </Section>
    </Mask>
  );
};
